@import url(https://fonts.googleapis.com/css?family=Avenir:400,500,700,900);
/* @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900'); */


/* .App {
  text-align: center;
}

.App-logo {
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
}

.App-title {
  font-size: 1.5em;
} */

*{
  font-family: 'Avenir', sans-serif;
  -webkit-font-smoothing: antialiased
}
.App,#root{
  background: #F0F3F7;
  font-family: 'Avenir', sans-serif;
  min-height: 100%;
}

@media only screen and (max-width: 576px) {
 .App, #root {
    background: #fafafa!important;
  }
}

.logo{
  /*height: 100px;*/
  /*width: 107.69px;*/
  /*box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  /* margin: 24px; */
  display: block;
  margin: 20px auto 50px auto;
}
.flex-wrap{
  display: -webkit-flex;
  display: flex;
  padding: 1em 0em;
  border-top: 1px solid rgba(0,0,0,0.09);
}

.hr-line{
  padding: 1em 0em;
  border-top: 1px solid rgba(0,0,0,0.09);
}
/* .p-t{
  padding-top: 1.5em;
} */
.f-r{
  float:right;
}
.mtb-20{
  margin-bottom: 20px;
}
.font14{
  font-size: 16px;
}
.font16 {
  font-size: 16px;
}
.vh-100{
  height: 100vh;
}
.font-weight{
  font-weight: 300;
}
.float-right {
  float: right;
}
.opacity-black-5{
background: rgba(0,0,0,0.6);
}
.absolute-loading{
  position: absolute;
  bottom:0;
  top:0;
  left:0;
  right:0
}
.opacity-white-5{
  background: rgba(255,255,255,0.6);
}
.opacity-transparent{
  background: transparent;
}

.z-index-1{
  z-index: 1;
}
.z-index-100{
  z-index: 99;
}
.loading{
    font-size: 40px;
    color: white;
}
.opacity-white-5 .loading{
  color:#0076FF;
}
.color-blue-6{
  color:#1890ff;
}
.border-right-grey{
  border-right: 1px solid #ccc;
}
.ant-collapse-item > .ant-collapse-header{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.anticon-right svg{
  float:right;
}

.linkedActivities > .ant-btn > .anticon > svg {
  margin: 0;
}
.uparrow{
  padding-bottom: 2.5rem;
}
@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-Black-03.968e7849.ttf) format("truetype");
  font-weight: 900;
}
@font-face{
  font-family: "Avenir";
  src: url(/static/media/Avenir-Medium-09.7d635ea9.ttf) format("truetype");
  font-weight: 500;
}
@font-face{
  font-family: "Avenir";
  src: url(/static/media/Avenir-Roman-12.c6d37b26.ttf) format('truetype');
  font-weight: 100;
}
@font-face{
  font-family: "Avenir";
  src: url(/static/media/Avenir-Heavy-05.f080b689.ttf) format('truetype');
  font-weight: 800;
}
@font-face{
  font-family: "Avenir";
  src: url(/static/media/Avenir-Book-01.995b34ab.ttf) format('truetype');
  font-weight: 700;
}
.FontAvenirRoman{
  font-family: "Avenir" !important;
  font-weight: 100 !important;
}
.FontAvenirBlack{
  font-family: 'Avenir' !important;
  font-weight: 900 !important;
}
.FontAvenirHeavy{
  font-family: 'Avenir' !important;
  font-weight: 800 !important;
}
.FontAvenirBook{
  font-family: 'Avenir' !important;
  font-weight: 700 !important;
}
.FontAvenirMedium{
  font-family: 'Avenir' !important;
  font-weight: 500!important;
}
.font-16{
  font-size: 16px;
}
.font-20{
  font-size: 20px;
}
.overflow-scroll{
  overflow:scroll
}
.font-14{
  font-size: 14px;
}
.font-18{
  font-size:18px;
}
.font-19{
  font-size: 19px !important;
}
.font-12{
  font-size: 12px;
}
.cursor-pointer{
  cursor: pointer;
}
.z-index-999{
  z-index: 1000;
}
.height-80{
  height: 80px;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
}
.height-40{
  height: 40px;overflow:scroll;overflow-y:auto;  overflow-x: hidden;

}
      .height-80::-webkit-scrollbar,.height-40::-webkit-scrollbar {
        /* display: none; */
      }
.ant-tabs-nav .ant-tabs-tab:last-child{
  /* float: right; */
}
.lc-onboarding__card .ant-checkbox-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;

}
.lc-onboarding__card .ant-checkbox-inner{
  margin-top: 0.25rem;
}
.ant-tabs-nav{
  width: 100%;
}
.TabActivities .ant-modal-footer
  {border-top: 1px solid #e8e8e8;
    padding: 0;
    text-align: right;
    margin-left: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 30px;
    border-radius: 0 0 4px 4px;
  }
  .fontColor-blue{
    color: #0076FF;
  }
  .pointer-cursor{
    cursor: pointer;
  }
  .pointer-normal{
    cursor: unset !important;
  }
  .featuredImage .ant-upload-list-item-done{
    display: none;
  }
  .activity-image .ant-upload-list-item-done{
    display: none;
  }
  .featuredImageUpload .ant-upload-list-item-done{
    display: none;
  }
  .pdf-upload .ant-upload-list-item-done{
    display: none;
  }
  .pdf-upload .delete-img {
    position: absolute;
    top: 0px;
    right: 1%;
    margin: 10px;
    padding: 0px 0.3rem;
    background-color: rgb(255, 255, 255);
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
  }
  .activity-steps-images {
    margin: 16px 0;
  }
  .activity-steps-images .delete-img {
    position: absolute;
    top: 0px;
    right: 10%;
    margin: 10px;
    padding: 0px 0.3rem;
    background-color: rgb(255, 255, 255);
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
  }
  @media (min-width: 1500px){
    .App.Container{
      max-width: 1500px;
      margin: 0 auto;
    }
  }

  .studentsDataTable .pagination {
    float: right;
  }
  .studentView {
    margin: 2rem 2rem 0rem;
  }
  .studentView .ant-card-body .ant-card-grid{
    padding: 0.625rem
  }

  .close-icon{
    width:18px;
    height: 18px;
  }
  /* .ant-calendar-date.present::after {
     content: '⋅';
    font-size: 2rem;
    position: relative;
    top: 14px;
    left: -14px;
    color: green;
  } */
.datePicker.ant-calendar-picker,
.datePicker.ant-calendar-picker input,
.datePicker.ant-calendar-picker div,
.datePicker.ant-calendar-picker i {
  visibility: hidden;
  width: 0px;
  border: 0px;
  height: 0px;
  padding: 0px;
  margin: 0px;
}
.ant-btn{
  font-weight: 500;
}

#errors {
  color: red;
}
.react-bs-table-no-data {
  text-align: center;
}
#has-error {
  border-color: #f5222d;
}
#has-error-label {
  color: #f5222d !important;
  border-color: #f5222d !important;
}
.has-error-ma .ant-collapse-header {
  color: #f5222d !important;
}
.has-error, .has-error .ant-upload.ant-upload-drag {
  border: 1px dashed #f5222d !important;
}
.linksIn404 {
  color: #0076ff;
  text-decoration: underline;
}
.linksIn404:hover {
  cursor: pointer;
}
.requiredAsterisk::after{
  display: inline-block;
  content: '*';
  font-family: SimSun;
  font-size: 14px;
  color: #f5222d;
  position: relative;
  top: -5px;
  margin-left: 2px;
}

.noNetError .ant-spin-text{
color: red;
font-weight: 500;
font-size: 20px;
}

.featuredImageUpload {
  height: 197px;
}
.featured-img-upload .delete-img {
  position: absolute;
  top: 0px;
  right: 25px;
  margin: 10px;
  padding: 0px 0.3rem;
  background-color: rgb(255, 255, 255);
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.index-number {
  position: absolute;
  top: 0px;
  left: 25px;
  margin: 10px;
  padding: 0px 0.3rem;
  background-color: rgb(255, 255, 255);
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  font-weight: 800;
  border-radius: 50%;
}

.delete-img-profile {
  position: absolute;
  top: 0px;
  right: 45px;
  margin: 10px;
  padding: 0px 0.3rem;
  background-color: rgb(255, 255, 255);
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.featured-img-upload{
  border-top: 0px !important;
  padding: 0 1rem;
  max-width: 100%;
}
.featured-img-upload img{
  width: auto;
  border-radius: 5px;
  height: auto;
}

.ant-calendar-footer-extra {
  text-align: right;
}

.text-transform-none {
  text-transform: none !important;
}
.stepsImages-filled-outline .outline-circle{
  display: block;
}
.outline-circle{
  display: none;
  width: 14px;
    height: 14px;
    background: #52C41A;
    border-radius: 50%;

}
.stepsImages-filled {
  background: #68B415;
  border: 1px solid #68B415;
  box-sizing: border-box;
  border-radius: 4px;
  color: #FFFFFF;
}

.stepsImages-empty {
  background: rgba(104, 180, 21, 0.15);
  border: 1px solid #68B415;
  box-sizing: border-box;
  border-radius: 4px;
  color: #68B415;
}
.stepsImages-empty-outline,.stepsImages-filled-outline{

background: rgba(230, 247, 255, 0.1);
border: 1px solid #BAE7FF;
box-sizing: border-box;
border-radius: 4px;
}
.stepsImages-empty-outline > div,.stepsImages-filled-outline > div{
  font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */

color: rgba(0, 0, 0, 0.65);
}
.stepsImages-empty-outline svg, .stepsImages-filled-outline svg{
  width: 12px;
}

.stepsImages-empty > .anticon > svg {
  margin: 0;
}

.stepsImages-filled > .anticon > svg {
  margin: 0;
}

.contents-table img {
  margin-right: 8px;
}

.contents-table img:last-child {
  margin-right: 0px;
}

.table-full table {
  box-shadow: 0px 1px 6px rgba(74, 144, 226, 0.5);
}

.table-full tbody td {
  vertical-align: top;
}

.table-full .anticon svg {
  margin: 0;
}

.table-row {
  background-color: white;
}

.updated-box {
  height: 22px;
  width: 80px;
  background: #F84E4E;
  border: 1px solid #CF220A;
  border-radius: 4px;
  color: #FFFFFF;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 8px;
}
.ant-layout-content .clearfix:before,.ant-layout-content  .clearfix:after{
  display: inline;
  display: initial
}
.word-break {
  word-wrap: break-word;
}
input.timeInput::-webkit-outer-spin-button,
input.timeInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.timeInput[type=number] {
  -moz-appearance:textfield;
}
.legendPreviousMonth,.legendNextMonth{
  width: 15px;
  height: 15px;
  border-radius: 4px;
  background: rgba(245,166,35,.3);
  border: 1px solid rgba(245,166,35,1);
}
.legendNextMonth{
  background: rgba(74,144,226,.3);
  border: 1px solid rgba(74,144,226,1);
}
.charDesc{
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 22px;
  color: #8C8C8C;
}

.long-description strong {
  font-weight: 900 !important;
}

.ant-table-thead > tr > th {
  border: 1px solid #E8E8E8;
}
.imgPreview {
  width: 450px;
  height: 250px;
}
.ril__image {
  /* width: 50%; */
  /* height: 70%; */
  /* margin-left: 250px!important; */
  object-fit: contain;
  object-position: center;
}
.css-6d2p78 {
  width: 1017px;
  height: 513px;
  object-fit: cover;
}


.signup-card-wrap .ant-card-body{
  width: 90%;
}

.navbarContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);
}
.navbarContainer > .ant-menu-horizontal > .ant-menu-item,
.navbarContainer > .ant-menu-horizontal > .ant-menu-submenu {
  font-size: 16px;
  font-weight: 500;
  color: #9b9b9b;
  padding: 0 10px;
}
.navbarContainer .ant-menu-item-group-list .ant-menu-item, .navbarContainer .ant-menu-item-group-list .ant-menu-submenu-title{
  padding:0 16px 0 10px;
}
.navbarContainer > .ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: #9b9b9b;
  font-size: 13px;
}
.navbarContainer.expert > .ant-menu-horizontal > .ant-menu-item,
.navbarContainer.expert > .ant-menu-horizontal > .ant-menu-submenu,
.navbarContainer.expert > .ant-menu-horizontal > .ant-menu-item > a {
  color:#FFF;
}
.navbarContainer.parent > .ant-menu-horizontal > .ant-menu-item,
.navbarContainer.parent > .ant-menu-horizontal > .ant-menu-submenu,
.navbarContainer.parent > .ant-menu-horizontal > .ant-menu-item > a {
  color:#FFF;
}
.navbarContainer.lc > .ant-menu-horizontal > .ant-menu-item,
.navbarContainer.lc > .ant-menu-horizontal > .ant-menu-submenu,
.navbarContainer.lc > .ant-menu-horizontal > .ant-menu-item > a {
  color:#FFF;
}

.navbarContainer.expert .navSelected, .navbarContainer.expert .ant-menu-item > a:hover{
  font-weight: 900;
  color: #FFF !important;
}
.navbarContainer.parent .navSelected, .navbarContainer.parent .ant-menu-item > a:hover{
  font-weight: 900;
  color: #FFF !important;
}
.navbarContainer.lc .navSelected, .navbarContainer.lc .ant-menu-item > a:hover{
  font-weight: 900;
  color: #FFF !important;
}

.navbarContainer > .ant-menu-horizontal > li > .ant-menu-item-group-list {
  display: -webkit-flex;
  display: flex;
}

.navbarContainer > .ant-menu-horizontal > .ant-menu-item:hover,
.navbarContainer > .ant-menu-horizontal > .ant-menu-submenu:hover,
.navbarContainer > .ant-menu-horizontal > .ant-menu-item-active,
.navbarContainer > .ant-menu-horizontal > .ant-menu-submenu-active,
.navbarContainer > .ant-menu-horizontal > .ant-menu-item-open,
.navbarContainer > .ant-menu-horizontal > .ant-menu-submenu-open,
.navbarContainer > .ant-menu-horizontal > .ant-menu-item-selected,
.navbarContainer > .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent;
  color: #0074fc;
  font-family: "Avenir", serif !important;
  font-weight: 800 !important;
}

.ant-menu-horizontal {
  border: 0px;
}

.active {
  color: green;
}

.navbarContainer .navSelected,
.navbarContainer .ant-menu-item > a:hover {
  color: #0076FF !important;
}

.navbarContainerAdmin {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);
}
.navbarContainerAdmin ul {
  background: #5432BA;
}
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-submenu {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: #FFFFFF;
  font-size: 13px;
}
.navbarContainerAdmin > .ant-menu-horizontal > li > .ant-menu-item-group-list {
  display: -webkit-flex;
  display: flex;
}
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item:hover,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-submenu:hover,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item-active,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-submenu-active,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item-open,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-submenu-open,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item-selected,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent;
  color: #ffe20d;
  font-family: "Avenir", serif !important;
  font-weight: 800 !important;
}

.notification-lc-card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  min-width: 400px;
  border-bottom: 1px solid #c8c8c8;
}

.notification-content-name {
  font-weight: 900;
}

.navbarContainerAdmin .navSelected,
.navbarContainerAdmin .ant-menu-item > a:hover {
  color: #ffe20d !important;
}

.no-lc-notifications {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2rem 1rem;
}

.no-lc-notifications img {
  height: 150px;
  width: 156px;
}

.no-lc-notifications > .no-notifications-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #4A4A4A;
  font-size: 16px;
  margin-top: 16px;
}

.ant-menu-item.submenu-popup-scrollarea,.ant-menu-submnu-popup {
  height: 50vh;
  overflow: scroll;
  background:transparent !important;
}
.ant-menu-item:hover.notification-popup{
  color:rgba(0, 0, 0, 0.65);
}
.ant-menu-item.clearFilter{
  height: auto;
  height: initial;
  background: #f8f8f8;
  color: #606060
}
.VersionContainer{
  position: fixed;
  bottom: 0;
  z-index: 998;
  right: 20px;
  background: #fff;
  padding: 8px 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 10px;
  font-family: Avenir;
font-style: normal;
font-weight: 500;
font-size: 10px;
/* line-height: 22px; */
box-shadow:  0px 1px 4px rgba(0, 0, 0, 0.25);
/* identical to box height, or 220% */
text-transform: uppercase;

color: #6F6F6F;
}
.navStrip{
  position: absolute;
  top: 0;
  overflow: hidden;
  left:-20px;
  bottom:0;
  right:0
}
.navStrip img{
  width:100%;
}
.expert.navbarContainer{
  background:#3EC1F0;
}
.expert.navbarContainer .ant-menu{
  background: #3EC1F0;
}
.expert.navbarContainer button{
  background: transparent;
  border-color: #fff;
  color:#fff;
}

.parent.navbarContainer{
  background: #5432BA;
}
.parent.navbarContainer .ant-menu{
  background: #5432BA;
}
.parent.navbarContainer button {
  background: transparent;
  border-color: #fff;
  color:#fff;
}

.lc.navbarContainer{
  background: #D8395C;
}
.lc.navbarContainer .ant-menu{
  background: #D8395C;
}
.lc.navbarContainer button {
  background: transparent;
  border-color: #fff;
  color:#fff;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: middle;
}
a.selectedLine {
  position: relative;
}
a.selectedLine::before {
  content: "";
  position: absolute;
  top: 75%;
  width: 100%;
  height: 3px;
  background: linear-gradient(360deg,#fff,#fff 45.07%,#fff 80.05%);
  }
.parentNotiCount.ant-badge-count {
  background: #F8C947;
  border-radius: 8px;
  color: black;
}
@media (max-width: 576px) {
  .ant-badge-count {
    color: #000;
    font-weight: 900;
    background: #F8C947;
  }
  .parentMenu {
    display: none;
  }
  .ant-drawer-header {
    border-bottom: none!important;
  }
  .mbl-drawer {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #000000;
  }
  .mbl-navText, .mbl-navText > a {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #000000;
    margin-bottom: 10px;
  }
  a.navSelected {
    color: #4C39B3;
    font-weight: 800;
  }
  .ant-drawer-body {
    height: 90%;
  }
  .mbl-parentMenu {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 200;
  }
}
@media (min-width: 576px) {
  .mbl-parentMenu, .mbl-sidemenu {
    display: none;
  }
}
.upload-btn {
  /*height: 128px;*/
  /*width: 128px;*/
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
  cursor: pointer;
}

.upload-btn:hover {
  border: 1px dashed #007bff;
}

.upload-btn svg {
  margin: 0;
}

.modal-upload .ant-upload {
  height: 200px;
  width: 400px;
}

.delete-image-btn {
  position: absolute;
  top: 3%;
  right: 3%;
  padding: 0px 0.3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.btn-collection {
  position: absolute;
  top: 3%;
  right: 3%;
  padding: 0px 0.3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.floating-btn {
  background-color: rgb(255, 255, 255);
  height: 20px;
  width: 20px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}

.ReactCrop__image {
  max-height: none;
  max-height: initial;
}
.svgUpload{
  color: #007bff;
  width: 30px;
  height: 30px;
}
.pwd-show{
        top: 11px;
    right: 12px;
}
.form-error{
    position: absolute;
    bottom: 5px;
    font-size: 12px;
    left: 0;
    color: #f5222d;
    display: none;
}
.formSubmitted .form-error{
    display: block;
}
.formSubmitted .is-invalid-input{
    border-color: #f5222d
}
.playlistCard {
  border-radius: 0.25rem;
  margin: 1rem;
  box-shadow: 2px 2px 9px 0px #d6d6d6;
}

.playlistCard .ant-card-body{
  height: 10.625rem;
}

.playlistCard .pTitle{
  text-transform: capitalize;
  color: black;
  font-weight: 500;
}

.linkedActivities .ant-list-item-content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center
}

.viewPlistCollapse {
  margin: 2rem 0rem 2rem;
}

.viewPlistCollapse label{
  color: rgba(0,0,0,0.85);
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin-top: 1rem;
  text-transform: uppercase;
}

.viewPlistCollapse .age_groups .borderful {
  border-right: 1px solid #000;
  padding: 0 0.65rem;
}

.viewPlistCollapse .age_groups .borderful:last-child {
  border-right: 0px;
}

.viewPlistCollapse .ant-row {
  margin-bottom: 1rem;
}

.plistViewTitle {
   box-shadow: 0 1px 0 1px rgba(0,0,0,0.05);
   border-radius: 4px;
}
.plistViewTitle .ant-card-head{
   border: 0px;
   padding-left: 16px;
}

.plistViewTitle .ant-card-head-title{
   color: rgba(0,0,0,0.85);
   font-size: 16px;
   text-transform: uppercase;
}

.plistViewTitle .ant-card-body{
   padding: 5px 32px 5px 16px;
}

.view-playlist .playlist-title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  text-transform: capitalize;
}
.text-initial{
  text-transform: none !important;
  text-transform: initial !important;
}

.linkedActivities .ant-list-item {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  padding: 10px;
  margin: 1rem;
  /* height: 50px; */
}

.linkedActivities .badge {
  border-radius: 4px;
  background-color: #36CFC9;
  color: #FFFFFF;
  height: 24px;
  width: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
}

.linkedActivities span {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin-left: 1rem;
}

.create-playlist-title .ant-card-body {
  padding: 2rem 1rem;
}

.createPlistCollapse {
  margin: 2rem 0;
}

.createPlistCollapse span {
  font-size: 16px;
}

.createPlistCollapse .interests-view-box {
  min-height: 40px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 8px;
}

.createPlistCollapse .link-act-lib svg{
  margin: 0
}

.createPlistCollapse .ant-list-item {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  padding: 10px;
  margin: 1rem;
}

.createPlistCollapse .badge {
  border-radius: 4px;
  background-color: #36CFC9;
  color: #FFFFFF;
  height: 24px;
  width: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
}

.createPlistCollapse .list-name {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin-left: 1rem;
}
.playlistUpload .ant-upload.ant-upload-select-picture-card,.playlistUpload .ant-upload-list-picture-card .ant-upload-list-item,.playlistUpload .ant-upload-list-item .ant-upload-list-item-uploading{
  width:300px;
  height:150px;
}
.PlaylistImgCard{
  width: 127px;
  height: 72px;
  border-radius: 8px;
}
.approvedButton{
  top:-19px;
  right:0
}
.has-error .ant-upload.ant-upload-select-picture-card{
  border:1px dashed #f5222d;
}
.SortItem{

  list-style-type: none;
}

.FilterCard .ant-card-body {
	padding: 0;
}
.FilterCard .ant-collapse-header {
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	padding: 1rem !important;
	font-family: 'Avenir' !important;
	font-weight: 500 !important;
	font-size: 19px;
	background: white;
}
.ParentFilterCard .ant-card-body {
	padding: 0;
}
.ParentFilterCard .ant-collapse-header {
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	padding: 1rem !important;
	font-family: 'Avenir' !important;
	font-weight: 500 !important;
	font-size: 19px;
	background: white;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
	font-style: normal;
	vertical-align: -.125em;
	text-align: center;
	text-transform: none;
	line-height: 0;
	font-size: 12px;
	position: absolute;
	display: inline-block;
	line-height: 46px;
	vertical-align: top;
	top: 50%;
	margin-top: 2px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	right: 16px;
}
.FilterCard .ant-collapse > .ant-collapse-item {
	border: 0;
}
.FilterCard .ant-collapse {
	border: 0;
}
.FilterCard .ant-collapse-content {
	border-top: 0;
}
.FilterCard .ant-collapse-content > .ant-collapse-content-box {
	border-top: 0;
	border-bottom: 1px solid #e8e8e8;
}
.FilterCard .ant-input {
	border: 0;
	border-bottom: 1px solid;
	border-radius: 0;
}
.FilterCard .ant-input:focus {
	outline: none;
	box-shadow: none;
}
.FilterCard div.ant-collapse > div:nth-child(2) > div.ant-collapse-header {
	/* padding-bottom: 0 !important; */
}
.ParentFilterCard .ant-collapse > .ant-collapse-item {
	border: 0;
}
.ParentFilterCard .ant-collapse {
	border: 0;
}
.ParentFilterCard .ant-collapse-content {
	border-top: 0;
}
.ParentFilterCard .ant-collapse-content > .ant-collapse-content-box {
	border-top: 0;
	border-bottom: 1px solid #e8e8e8;
}
.ParentFilterCard .ant-input {
	border: 0;
	border-bottom: 1px solid;
	border-radius: 0;
}
.ParentFilterCard .ant-input:focus {
	outline: none;
	box-shadow: none;
}
.ParentFilterCard div.ant-collapse > div:nth-child(2) > div.ant-collapse-header {
	/* padding-bottom: 0 !important; */
}
.FilterCard {
	height: calc(100vh - 90px);
	overflow: scroll;
}
.ParentFilterCard {
	height: calc(140vh - 20px);
	overflow: scroll;
}
.ChildrenImgFilter {
	height: 35px;
	width: 35px;
	border-radius: 50%;
	border: 1px solid #828282;
	padding: 5px;
}
.childFilterText {
	font-family: Avenir;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #333333;
	text-transform: capitalize;
	padding: 10px;
}

@media only screen and (max-width: 576px) {
	.ParentFilterCard {
	  display: none!important;
	}
  }

.sortBar .active{
    font-family: 'Avenir';
    font-weight: 900;
    color: #0076FF;
}
.publish .tabscroll{
    overflow: scroll;
    height: calc( 100vh - 280px )
}
.add-collec-btn, .add-collec-btn:hover, .add-collec-btn:focus {
  background: #68B415;
  border: #68B415 1px solid;
}

.delete-btn, .delete-btn:hover, .delete-btn:focus {
  background: #f40000;
  border: #f40000 1px solid;
}

.btn-w250 {
  width: 250px;
}

.btn-w250[disabled]:hover, .btn-w250[disabled]:focus {
  color: rgba(0, 0, 0, 0.25)!important;
  background-color: #f5f5f5!important;
  border-color: #d9d9d9!important;
  text-shadow: none!important;
  box-shadow: none!important;
}

.btn-w250:hover, .btn-w250:focus {
  color: #ffffff!important;
  border-color: #1890ff!important;
}

.workshopApprbtn, .workshopApprbtn:hover, .workshopApprbtn:focus  {
  width: 200px;
  color: #ffffff!important;
  background: #68B415!important;
  border-radius: 4px;
  border-color: #68B415!important;
}

.workshopApprbtn[disabled], .workshopApprbtn[disabled]:hover, .workshopApprbtn[disabled]:focus {
  color: rgba(0, 0, 0, 0.25)!important;
  background-color: #f5f5f5!important;
  border-color: #d9d9d9!important;
  text-shadow: none!important;
  box-shadow: none!important;
}

.btn-w300 {
  width: 300px;
}

.btn-w350 {
  width: 350px;
}

.ActivityCard .ant-card-body{
    height: 100%;
    padding: 20px;
}
.ActivityCard .ant-card-meta-detail{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
}
.ActivityCard .ant-card-meta-title{
    font-family: 'Avenir';
    font-weight: 900 !important;
    font-size: 24px !important;
    z-index: 1;
    color: white;
}
.ActivityCard .ant-card-meta-description{
    font-family: 'Avenir';
    font-weight: 500 !important;
    font-size:19px !important;
    line-height: normal;
    line-height: initial;
    z-index: 1;
    color: white;
}
.int-hgt{
    height: 45px;
}
.ActivityCard img{
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
}
.ActivityCardView .ant-card-meta-title{
    font-family: 'Avenir';
    font-weight: 500 !important;
    font-size: 16px !important;
    z-index: 1;
    margin-bottom: 1rem;

}
.ActivityCardView .ant-card-body{
    height: 100%;
    padding: 0;
}
.position-relative .floatingblockimage{
    bottom: 10px;
    right: 10px;
    background: rgba(255,255,255,.90);
    border-radius: .20rem;
}
.floatingblockimage img {
    margin-right: 8px;
}
.floatingblockimage img:last-child {
    margin-right: 0;
}
.ActivityCardView .ant-card-meta{
    margin: 0;
}
.ActivityCardView .ant-card-meta-detail{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 100%;
    padding:15px;
}
.ActivityCardView .ant-card-meta-description,.text-card-black{
    color: #4A4A4A;
}

.interestCard .ant-card-meta-description {
  margin-top: 2.5rem;
}

.categoryCard .ant-card-head {
    border-bottom: 0;
}

.categoryCard .ant-card-meta-description {
    min-height: 105px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

.categoryCard .triple-dot {
    border-radius: 50%;
    background: white;
    height: 5px;
    width: 5px;
}

.triple-dot:not(:last-child){
    margin-right: 3px;
}

.interestCard {
   box-shadow: 2px 2px 9px 0px #d6d6d6;
}

.interestCard .ant-card-head {
    border-bottom: 0;
}

.card-int-hgt{
    height: 100px;
}
.draft-tile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    background-color: #DA375B;
    color: #FFFFFF;
    z-index: 10;
    text-align: center;
}

.image-card .ant-skeleton-active {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
}

.image-card .ant-skeleton-paragraph {
    padding: 16px;
}
.dashboardCardStyle{
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(0,0,0,0.05);
    border-radius: 4px;
    overflow: hidden;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
}
.caption{
    font-size: 16px;
}
svg > g > g:nth-child(4){
		-webkit-transform: scale(1.12);
		        transform: scale(1.12);
		overflow: visible !important;
}
.shape {
	fill-opacity: .3;
}
.radarchart svg{
	    -webkit-transform: scale(1.3);
	            transform: scale(1.3);
    margin-top: 50px;
}
.shape:hover {
	fill-opacity: .65;
	z-index: 100;
}

.scale {
	fill: #FAFAFA;
	stroke: #999;
	stroke-width: .2
}

.axis {
	stroke: #555;
	stroke-width: .2
}

.dot {
  /* fill: white;
  stroke: #e7e8e7; */
  r: 3;
  transition: r .2s;
}

.dot:hover {
  stroke: #bbb;
  r: 3;
}

.caption {
	fill: #444;
	    font-family: 'Avenir' !important;
    font-weight: 500!important;
	text-shadow: 1px 1px 0 #fff
}
.sb-soundplayer-icon {
  width: 1em;
  height: 1em;
  position: relative;
  vertical-align: middle
}

.sb-soundplayer-progress-container {
  background-color: #dee0e1;
  width: 100%;
  height: 8px;
  min-width: 150px;
  overflow: hidden;
  cursor: pointer
}

.sb-soundplayer-progress-inner {
  background-color: #1890ff;
  height: 100%;
  transition: width .2s ease-in
}

.sb-soundplayer-cover {
  color: #fff;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  padding: 30px 30px 20px;
  margin-bottom: 20px;
  height: 440px;
  position: relative;
  overflow: hidden;
  cursor: default;
  border-radius: 2px
}

.sb-soundplayer-cover:after {
  content: "";
  background: linear-gradient(rgba(0, 0, 0, .05), rgba(0, 0, 0, .35));
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.sb-soundplayer-cover .sb-soundplayer-info-box {
  display: inline-block;
  background-color: rgba(0, 0, 0, .8);
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 1px
}

.sb-soundplayer-cover .sb-soundplayer-track {
  font-weight: 600;
  font-size: 24px
}

.sb-soundplayer-cover .sb-soundplayer-artist {
  font-size: 18px
}

.sb-soundplayer-cover-logo {
  color: hsla(0, 0%, 100%, .9);
  width: 100px;
  height: 14px;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2
}

.sb-soundplayer-volume {
  position: relative;
  transition: background .8s ease;
  border-radius: 50px;
  padding:10px
}
.sb-soundplayer-volume:hover{
  background:#e2e2e2;
  margin-right: 10px;

}
.sb-soundplayer-volume:hover div {
  width: 80px;
  overflow: visible;
  overflow: initial;

}

.sb-soundplayer-volume div {
  overflow: hidden;
  width: 0;
  margin-bottom:0;
  display: -webkit-flex;
  display: flex;
  transition: width .2s ease-out;
  position: relative
}
/* .sb-soundplayer-volume:hover div{

} */
.sb-soundplayer-volume-range {
  -webkit-appearance: none;
  /* margin-top: 10px; */
  /* margin-left:10px; */
  width: 98%;

}

.sb-soundplayer-volume-range:focus {
  outline: none
}

.sb-soundplayer-volume-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .2em;
  cursor: pointer;
  animate: .2s;
  background: #f1f1f1;
  border-radius: 1.3px
}

.sb-soundplayer-volume-range::-moz-range-track {
  width: 100%;
  height: .2em;
  cursor: pointer;
  animate: .2s;
  background: #f1f1f1;
  border-radius: 1.3px
}

.sb-soundplayer-volume-range::-ms-track {
  width: 100%;
  height: .2em;
  cursor: pointer;
  animate: .2s;
  background: #f1f1f1;
  border-radius: 1.3px
}

.sb-soundplayer-volume-range::-moz-range-thumb {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #1890ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -.4em
}

.sb-soundplayer-volume-range::-webkit-slider-thumb {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #1890ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -.4em
}

.sb-soundplayer-volume-range::-ms-thumb {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #1890ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -.4em
}

.sb-soundplayer-volume-range::-ms-fill-lower {
  background: #1890ff
}
.sb-soundplayer-timer{
  /* min-width: 100px; */
  white-space: nowrap;
  font-size: 12px;
  color: #000;
  font-weight: 500;
}
.sb-soundplayer-progress-container{
  height: 5px;
}
.rounded-left {
  border-radius: 3px 0 0 3px
}

.button-transparent {
  position: relative;
  z-index: 2;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
  border: 1px solid transparent
}

.button-transparent:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: -var(--border-width);
  right: -var(--border-width);
  bottom: -var(--border-width);
  left: -var(--border-width);
  border: 1px solid transparent;
  background-color: currentcolor;
  opacity: 0
}

.button-transparent:hover {
  box-shadow: none
}

.button-transparent:focus:before,
.button-transparent:hover:before {
  opacity: .09375
}

.button-transparent:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 2px
}

.button-transparent.is-active,
.button-transparent:active {
  box-shadow: none
}

.button-transparent.is-active:before,
.button-transparent:active:before {
  opacity: .0625
}

.button-transparent.is-disabled,
.button-transparent:disabled {
  opacity: .5
}
.flex {
  display: -webkit-flex;
  display: flex
}
.flex-column {
  -webkit-flex-direction: column;
          flex-direction: column
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap
}
.flex-center {
  -webkit-align-items: center;
          align-items: center
}
.mr2 {
  /* margin-right: 16px; */
  padding-right: 1rem
}

.flex-none {
  -webkit-flex: none;
          flex: none
}

.button {
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 8px 16px;
  padding: .5rem 1rem;
  margin: 0;
  height: auto;
  border: 1px solid transparent;
  vertical-align: middle;
  -webkit-appearance: none
}

.button,
.button:after,
.button:before
 {
  box-sizing: border-box
}

::-moz-focus-inner {
  border: 0;
  padding: 0
}

.button:hover,.button:focus {
  text-decoration: none;
  box-shadow: none;
}
.transform-180,.sb-soundplayer-volume-btn{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg)
}
.sb-soundplayer-volume-btn,.sb-soundplayer-play-btn{
  padding-top:0;
  padding-bottom: 0;
  padding-right: 10px;
  padding-left: 10px;
}
.sb-soundplayer-volume-btn{
  padding-right: 0;
  margin-right: 10px;
  padding-left: 0;
}
.sb-soundplayer-play-btn{
  padding-right: 10px;
}
.sb-soundplayer-play-btn svg{
  width:.7rem;
}
.audioContainer{
  /* padding-left:30px; */
  background: #f1f3f4;
  border-radius: 50px;
}
.pointer-events-none{
  pointer-events: none;
}
.bars {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-height: 130px;
  border-bottom: 1px solid #E0DCDC;
  margin-bottom: 1rem;
  /*     background: black; */
}

.no-animation-bars {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-height: 130px;
  border-bottom: 1px solid #E0DCDC;
  margin-bottom: 1rem;
}

.bars .bar {
  background: #3EC0F1;
  bottom: 1px;
  height: 3px;
  width: 5px;
  margin: 0px 4px;
  border-radius: 5px;
  -webkit-animation: sound 0ms -600ms linear infinite alternate;
          animation: sound 0ms -600ms linear infinite alternate;
}

.no-animation-bars .bar {
  background: #3EC0F1;
  bottom: 1px;
  height: 3px;
  width: 5px;
  margin: 0px 4px;
  border-radius: 5px;
  /*animation: sound 0ms -600ms linear infinite alternate;*/
}

@-webkit-keyframes sound {
  0% {
    opacity: .35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 70px;
  }
}

@keyframes sound {
  0% {
    opacity: .35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 70px;
  }
}

.bar:nth-child(1)  { left: 1px; -webkit-animation-duration: 474ms; animation-duration: 474ms; }
.bar:nth-child(2)  { left: 15px; -webkit-animation-duration: 433ms; animation-duration: 433ms; }
.bar:nth-child(3)  { left: 29px; -webkit-animation-duration: 407ms; animation-duration: 407ms; }
.bar:nth-child(4)  { left: 43px; -webkit-animation-duration: 458ms; animation-duration: 458ms; }
.bar:nth-child(5)  { left: 57px; -webkit-animation-duration: 400ms; animation-duration: 400ms; }
.bar:nth-child(6)  { left: 71px; -webkit-animation-duration: 427ms; animation-duration: 427ms; }
.bar:nth-child(7)  { left: 85px; -webkit-animation-duration: 441ms; animation-duration: 441ms; }
.bar:nth-child(8)  { left: 99px; -webkit-animation-duration: 419ms; animation-duration: 419ms; }
.bar:nth-child(9)  { left: 113px; -webkit-animation-duration: 487ms; animation-duration: 487ms; }
.bar:nth-child(10) { left: 127px; -webkit-animation-duration: 442ms; animation-duration: 442ms; }
.bar:nth-child(11)  { left: 57px; -webkit-animation-duration: 400ms; animation-duration: 400ms; }
.bar:nth-child(12)  { left: 71px; -webkit-animation-duration: 427ms; animation-duration: 427ms; }
.bar:nth-child(13)  { left: 85px; -webkit-animation-duration: 441ms; animation-duration: 441ms; }
.bar:nth-child(14)  { left: 99px; -webkit-animation-duration: 419ms; animation-duration: 419ms; }
.bar:nth-child(15)  { left: 113px; -webkit-animation-duration: 487ms; animation-duration: 487ms; }
.bar:nth-child(16) { left: 127px; -webkit-animation-duration: 442ms; animation-duration: 442ms; }
.bar:nth-child(17)  { left: 57px; -webkit-animation-duration: 400ms; animation-duration: 400ms; }
.bar:nth-child(18)  { left: 71px; -webkit-animation-duration: 427ms; animation-duration: 427ms; }
.bar:nth-child(19)  { left: 85px; -webkit-animation-duration: 441ms; animation-duration: 441ms; }
.bar:nth-child(20)  { left: 99px; -webkit-animation-duration: 419ms; animation-duration: 419ms; }
.bar:nth-child(21)  { left: 113px; -webkit-animation-duration: 487ms; animation-duration: 487ms; }
.bar:nth-child(22) { left: 127px; -webkit-animation-duration: 442ms; animation-duration: 442ms; }
.bar:nth-child(23)  { left: 113px; -webkit-animation-duration: 487ms; animation-duration: 487ms; }
.bar:nth-child(24) { left: 127px; -webkit-animation-duration: 442ms; animation-duration: 442ms; }

.audio-recorder {
  width: 100%;
}

.recorder-icons .anticon svg {
  margin: 0;
}

.line-in-middle {
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent calc(15% - 0.81px),
    #66beeb calc(15% - 0.8px),
    #66beeb calc(15% + 0.8px),
    transparent calc(15% + 0.81px),
    transparent 100%
  );
}

.play-audio-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 4px 20px;
  border: 1px solid #3ec0f1;
  border-radius: 15px;
}

.time-card {
  padding-left: 0;
  /*padding-right: 0;*/
  float: right;
  border: 0px;
  background: transparent;
}

.time-card > .ant-card-body {
  padding-left: 0;
  padding-right: 13px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.time {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.67;
}
.time-card.journal .time {
  font-family: Nunito, sans-serif;

  font-size: 16px;
  line-height: 27px;
  /* identical to box height, or 169% */

  text-align: center;

  color: #000000;
}
.feedback-card {
  background-color: #ffffff;
  border: 1px solid #3ec0f1;
  border-radius: 5px;
  padding-bottom: 0;
}

.journal-card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 118, 255, 0.08);
  border: none;
  border-radius: 5px;
  padding-bottom: 0;
}

.activity-card-completed {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 118, 255, 0.08);
  border: none;
  border-radius: 5px;
  padding-bottom: 0;
}

.activity-card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 118, 255, 0.08);
  border: none;
  border-radius: 5px;
  padding-bottom: 0;
}

.activity-card-completed > .ant-card-body {
  padding-bottom: 5px;
}

.card-title-pd {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-bottom: 0;
}

.dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #4a4a4a;
  margin: 8px;
}

.lc-name {
  color: #4a4a4a;
  font-size: 14px;
  margin: 8px 0;
  opacity: 0.67;
}

.activity-name {
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.67;
  margin: 16px 0 8px 0;
}

.activity-subtitle {
  color: #9a9a9a;
  mix-blend-mode: normal;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.activity-subtitle span {
  color: #3ec1f0;
  mix-blend-mode: normal;
}

.activity-done {
  margin: 16px 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.activity-done > span {
  margin-left: 8px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.67;
}

.activity-completed {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.activity-completed > span {
  font-size: 16px;
  color: #147ef9;
  mix-blend-mode: normal;
  opacity: 0.67;
  font-weight: 800;
  margin-left: 8px;
}

.journal-title {
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.67;
  /*margin: 16px 0 8px 0;*/
}

.journal-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-top: 16px;
  min-height: 20px;
}

.journal-content > img {
  width: 300px;
  height: 169px;
  object-fit: cover;
  border-radius: 5px;
}

.journal-data {
  font-size: 16px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.67;
  margin: 8px;
}

.feedback-response {
  background-color: #effbff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 1rem;
  margin-top: 16px;
  border-radius: 5px;
}

.feedback-response i {
  color: #3ec0f1;
  margin: 4px 8px 0 0;
  font-size: 16px;
}

.feedback-response p {
  margin: 0;
  color: #40b7f5;
  font-weight: 600;
  font-size: 16px;
}
.activityImage {
  width: 150px;
  height: 80px;
  border-radius: 5px;
}

.floatingFixed {
  position: fixed;
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  top: 0;
  right: 0;
  /* background:#fff; */
  width: 0%;
  height: 100vh;
  overflow: scroll;
  max-height: 100vh;
  overflow: hidden;
  transition: width 0.5s ease-in-out;
}
.floatingFixed > div {
  min-width: 100vw;
}
.floatingFixed.active {
  width: 100%;
  overflow: scroll;
}
.whiteContainer {
  background: #fff;
}
.heading {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.desc {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a0a0a0;
}
.desc.editor strong {
  font-weight: 900;
}
.journal-title-small {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.journal-title-link {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #0084ff;
}
.journal-title-link.small {
  font-size: 14px;
}
.bg-colored {
  background: #f8fbff;
  height: 100%;
}
.opacityContainer {
  width: 20%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.48);
}
.fixedContainer {
  width: 80%;
  height: 100vh;
  background: #f8fbff;
  overflow: scroll;
}
.disabledContainer {
  opacity: 0.6;
  pointer-events: none;
}

.milestone-title {
  font-family: Avenir;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  text-transform: capitalize;
  color: #5432ba;
  margin-bottom: 8px;
}

.milestone-content {
  font-family: Avenir;
  font-size: 16px;
  line-height: 22px;
  color: #484848;
}

.milestone-count-bg {
  height: 89px;
  width: 89px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.milestone-count {
  font-family: Avenir;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  color: #ec1a58;
}

.journal-scrolltotop {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: #0076ff;
  border-radius: 6px;
  height: 60px;
  width: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #ffffff;
  font-size: 17px;
  cursor: pointer;
  z-index: 99;
}

.journal-notes {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.share_img_size {
  width: 80%;
}

.share_center {
  -webkit-justify-content: center;
          justify-content: center;
}

@media only screen and (max-width: 576px) {
  .max-journal-titles, .play-audio-btn, .milestone-count-bg, .max-dev-workshopsComp {
    display: none!important;
  }
  .mbl-journal-card {
    background: #F8FBFF!important;
    border: 1px solid #F8FBFF!important;
  }
  .mbl-journal-cardRow {
    margin: 10px 0 15px 0!important;
  }
  .time-card > .ant-card-body {
    padding-right: 8px;
  }
  .time {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: #363636;
    opacity: unset;
  }
  .mbl-journal-img {
    width: 49px;
    height: 49px;
    margin-top: 2px!important;
  }
  .mbl-journal-img1 {
    width: 49px;
    margin-top: 2px!important;
  }
  .mbl-journal-Imgcomp {
    width: 32px;
    margin-top: 2px!important;
  }
  .categoryCard {
    min-width: 242px!important;
    max-width: 300px!important;
    height: 165px!important;
  }
  .activity-name {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #212121;
    margin: 5px 0 0 0;
    opacity: unset;
  }
  .mbl-journal-Empty {
    width: 270px;
    height: 165px;
  }
  .journal-title {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    opacity: unset;
    color: #000000;
  }
  .mbl-journal-EmptyDate {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #000000;
  }
  .mbl-journalFeed-img {
    width: 60%;
    margin-top: 2px!important;
  }
  .mbl-notes-modal {
    width: 100%!important;
    height: 100%!important;
  }
  .mbl-feedback-username {
    font-size: 12px!important;
  }
  .lc-name {
    color: #000000;
    opacity: unset;
  }
  .mbl-play-audio-btn {
    border: 1px solid #3EC0F1;
    box-sizing: border-box;
    padding: 10px;
    width: 50%;
    border-radius: 50px;
  }
  .milestone-title {
    font-size: 16px;
    line-height: unset;
  }
  .milestone-content {
    font-size: 14px;
  }
  .mbl-playlist-img {
    width: 45px;
  }
  .mbl-milestone-count-bg {
    width: 45px;
    height: 45px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .milestone-count {
    font-weight: 800;
    font-size: 12px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 576px) {
  .mbl-journal-titles, .mbl-play-audio-btn, .mbl-milestone-count-bg, .mbl-dev-workshopsComp {
    display: none!important;
  }
}
.interest-image .clearfix .ant-upload-select-picture-card{
  height: 130px;
  width: 130px;
  margin: 1rem 0;
}

.interest-image .clearfix .ant-upload-list-item-done, .interest-image .clearfix .ant-upload-list-item-uploading {
  height: 130px;
  width: 130px;
  margin: 1rem 0;
}

.interest-image .clearfix img {
  height: 110px;
  width: 110px;
}

.mb-8px {
  margin-bottom: 8px;
}

.edit-button svg {
  margin: 0;
}

/* .reset-btn button{
    border: 1px solid #1890ff;
    color: #1890ff;
}
.reset-btn{
    margin-bottom: 0;
    padding-bottom:0;
} */

.reset-btn button{
    border: 1px solid #1890ff;
    color: #1890ff;
}
.reset-btn{
    margin: 0;
}
.hint{
    margin-bottom: 1.5em;
    font-size: 13px;
    color:#9B9B9B;
}
.ant-form-item-required:before{
    content: "";
}

.hr-line{
    padding: 1em 0em;
    border-top: 1px solid rgba(0,0,0,0.09);
}
.heading{
    padding-bottom: 8px;
}


.card-wrap{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100vh;
    /* margin-bottom: 0; */
    border-radius: 4px;
    margin:0 auto;
}
.t-center{
    text-align: center;
}

.txt-bold{
    font-weight: 500;
}
.m-b{
    margin-bottom: 1em;
}
.mb-0{
    margin-bottom: 0em;
}
.m-rl{
    margin:0em 0.5em;
}
.card-radius{
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
  width:400px;
}
.btn-signin{
    padding-top: 1.2em;
    border-top: 1px solid rgba(0,0,0,0.09);
    margin-bottom: 10px;
}
a:focus{
    text-decoration: none;
}
.mbl-card-radius {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1)!important;
    border-radius: 12px;
}
.text-darkBlack {
    color: #000!important;
}
.signupLogo {
    margin: 0;
}
.max-dev-NotFoundTxt {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #EA1F59;
    background: #FFDCE6;
    border-radius: 5px;
    padding: 12px;
}

@media (max-width: 576px) { 
    .logo, .max-signup-title, .max-alreadymem, .max-dev-forgotPass,
    .max-dev-stickTxt, .max-btn-hide, .signupLogo, .max-dev-NotFoundTxt {
        display: none!important;
    }
    .txt-footr {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #000000;
    }
    .mbl-logo {
        display: block;
        margin: 15px auto 30px auto;
        height: 40px;
    }
    .mbl-signup-logo {
        width: 249px;
        display: block;
        margin: 15px auto 20px auto;
        height: 40px;
    }
    .mbl-cardStart {
        width: 90%;
        background: #FFFFFF;
    }
    .card-wrap {
        border-radius: 6px;
        background: #fafafa;
    }
    .card-w-auto {
        width: auto!important;
        box-shadow: none;
    }
    .btn-social, .ORTXT {
        display: none!important;
    }
    .social-ico {
        cursor: pointer;
    }
    .lap-footer {
        margin-bottom: 3rem;
    }
    .mbl-accountTxt {
        color: #333!important;
        font-size: 15px!important;
        font-weight: normal!important;
    }
    .mbl-signupTxt {
        text-decoration: none!important;
        color: #0076FF;
        font-size: 15px!important;
        font-weight: 800!important;
    }
    .mbl-signup-card {
        width: 90%!important;
        border: 1px solid #fff;
    }
    .mbl-signup-title {
        color: #000!important;
        font-weight: 500!important;
    }
    .mbl-alreadymem {
        color: #828282!important;
        font-weight: 500!important;
    }
    .mbl-alreadymem > a > span {
        font-weight: 800!important;
    }
    .text-secondary {
        color: #000!important;
    }
    .ant-card-body {
        width: 90%;
    }
    .mbl-btn-bottom {
        margin-bottom: 1rem!important;
    }
    .mbl-signup-input::-webkit-input-placeholder {
        font-family: Avenir;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
        color: #BDBDBD;
    }
    .mbl-signup-input::placeholder {
        font-family: Avenir;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
        color: #BDBDBD;
    }
    .mbl-forgot-logo {
        height: 40px;
    }
    .mbl-dev-forgotPass {
        height: 90%;
        width: 90%;
    }
    .mbl-forgotPass-input > div > .FontAvenirMedium {
        text-transform: none!important;
        text-transform: initial!important;
    }
    .mbl-fontTxt {
        text-transform: uppercase;
        color: #000000!important;
    }
 }
 @media (min-width: 576px) { 
    .mbl-footer, .txt-footr, .mbl-signup-title, .mbl-alreadymem, .mbl-signup-logo, .mbl-forgot-logo,
    .mbl-dev-forgotPass, .lap-footer, .mbl-btn-hide {
        display: none!important;
    }
    .mbl-fontTxt {
        font-size: 30px!important;
        color: #000000!important;
        margin-top: 1.5rem;
    }
    .mbl-logo {
        margin-top: 1.5rem;
        height: 50px;
    }
    .max-dev-stickTxt {
        margin-bottom: 1.5rem;
    }
    .mbl-card-radius > .ant-card-body {
        padding: 0;
        width: 390px;
        margin-bottom: 3rem;
    }
 }

.enroll-card{
    width:900px;
    border-radius: 5px;
}
.f-r{
    float:right;
}
.lc-onboarding .ant-checkbox-wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: space-between;
            justify-content: space-between
}
.lc-onboarding .ant-checkbox{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.lc-onboarding .ant-checkbox-group-item{
-webkit-flex-direction: row;
        flex-direction: row;
}
.lc-onboarding__card{
    padding: 10px;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.15);
    margin-left:20px;
    margin-bottom:10px;
    margin-top:10px;
    height: 150px;
    background-repeat: round;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.lc-onboarding__card .ant-checkbox-inner{
    position: relative;
    top:40%;
}
.lc-onboarding .chkbox{
    padding: 1.5em;
    /* border: 1px dashed #1890FF; */
    border-radius: 4px;
    border: 0.5px dashed #4A90E2;
    background-color: #ECF3FC;
}
.hr-line{
    padding: 1.5em 0em;
    border-top: 1px solid rgba(0,0,0,0.09);
}
.heading{
    padding-bottom: 8px;
}
.font-b{
    color: #0076FF;
}

/* LCEnroll1 */
.min-hgt{
    min-height: 105px !important;
}

/* .ant-upload-list-item-done{
    display: none;
} */


.lc-o1 .ant-row .ant-form-item{
    margin-bottom: 0px;
}

.lc-o1 .ant-form-item-label{
    padding-bottom: 1em;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  position: absolute;
  display: inline-block;
  line-height: 46px;
  vertical-align: top;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 16px;
}

.age {
  display: inline-block;
}

.age .ant-select {
  width: auto;
  min-width: 100px;
}

.age .ant-select .ant-select-selection--single {
  border: none;
  box-shadow: none;
  margin-right: 30px;
}

.save-activity-btn {
  float: right;
  position: relative;
  bottom: 7em;
}

.btn-wrapper {
  float: right;
  margin-top: 16px;
}

.btn-wrapper button {
  height: 34px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 2px;
}

.btn-wrapper button:first-child {
  margin-right: 8px;
}

.create-steps {
  padding: 20px;
  background-color: #fcfeff;
  border: 1px solid rgba(0, 118, 255, 0.2);
  border-radius: 4px;
}

.featuredImage .featured-img img {
  width: 378px;
  border-radius: 5px;
}

.featuredImage .featured-img {
  padding: 0;
}

.create-steps .ant-upload.ant-upload-drag .ant-upload-btn {
  min-height: 170px;
}

.color, .color .ant-select-arrow {
  color: #1890ff;
}

.image_details {
  clear: both;
}

.create-activity .ant-collapse-header {
  background: #e2f4ff;
  border-color: #e2f4ff;
  font-family: "Avenir", sans-serif !important;
  font-weight: 100 !important;
  font-size: 16px;
}

.addmorebutton {
  padding: 5px;
  border: 1px solid #0076ff;
  color: #0076ff;
}

.border-color-blue {
  border-color: #bae7ff !important;
}

.create-activity .ant-collapse {
  border: none;
  background: transparent;
}

.activityPageScroll {
  /* height: calc( 100vh - 190px); */
  overflow: scroll;
}

.tabscroll {
  height: calc(100vh - 215px);
  overflow: scroll;
}

.min-300 {
  max-width: 300px;
}

.ActivityBorder th {
  height: 3rem;
}

.ActivityBorder tr {
  margin: 0.5rem;
}

.btn-wrapper .anticon svg {
  margin: 0;
}

.step-cancel, .step-cancel:hover {
  color: #9B9B9B;
  border: 1px solid #9B9B9B;
}
.text-opt{
  font-size: 16px;
line-height: 22px;
/* identical to box height, or 137% */

color: #9B9B9B
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  position: absolute;
  display: inline-block;
  line-height: 46px;
  vertical-align: top;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 16px;
}

.age {
  display: inline-block;
}

.age .ant-select {
  width: auto;
  min-width: 100px;
}

.age .ant-select .ant-select-selection--single {
  border: none;
  box-shadow: none;
  margin-right: 30px;
}

.save-activity-btn {
  float: right;
  position: relative;
  bottom: 7em;
}

.btn-wrapper {
  float: right;
  margin-top: 16px;
}

.btn-wrapper button {
  height: 34px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 2px;
}

.btn-wrapper button:first-child {
  margin-right: 8px;
}

.create-steps {
  padding: 20px;
  background-color: #fcfeff;
  border: 1px solid rgba(0, 118, 255, 0.2);
  border-radius: 4px;
}

.featuredImage .featured-img img {
  width: 378px;
  border-radius: 5px;
}

.featuredImage .featured-img {
  padding: 0;
}

.create-steps .ant-upload.ant-upload-drag .ant-upload-btn {
  min-height: 170px;
}

.color, .color .ant-select-arrow {
  color: #1890ff;
}

.image_details {
  clear: both;
}

.create-activity .ant-collapse-header {
  background: #e2f4ff;
  border-color: #e2f4ff;
  font-family: "Avenir", sans-serif !important;
  font-weight: 100 !important;
  font-size: 16px;
}

.addmorebutton {
  padding: 5px;
  border: 1px solid #0076ff;
  color: #0076ff;
}

.border-color-blue {
  border-color: #bae7ff !important;
}

.create-activity .ant-collapse {
  border: none;
  background: transparent;
}

.activityPageScroll {
  /* height: calc( 100vh - 190px); */
  overflow: scroll;
}

.tabscroll {
  height: calc(100vh - 215px);
  overflow: scroll;
}

.min-300 {
  max-width: 300px;
}

.ActivityBorder th {
  height: 3rem;
}

.ActivityBorder tr {
  margin: 0.5rem;
}

.btn-wrapper .anticon svg {
  margin: 0;
}

.step-cancel, .step-cancel:hover {
  color: #9B9B9B;
  border: 1px solid #9B9B9B;
}
.text-opt{
  font-size: 16px;
line-height: 22px;
/* identical to box height, or 137% */

color: #9B9B9B
}
.circle-indicator {
  width: 10px;
  height: 10px;
  background: transparent;
}

.ant-collapse-header {
  background: #e2f4ff;
  border-color: #e2f4ff;
  font-family: "Avenir", sans-serif !important;
  font-weight: 100 !important;
  font-size: 16px;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.box-sdw-rad {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
}

.featured-img {
  border-top: 1px solid #e8e8e8;
  padding: 1rem;
}

.b-r {
  border-right: 1px solid #d8d8d8;
}

.pdl-2 {
  padding-left: 2rem;
}

.featured-img img,
.steps-img img {
  height: auto;
  width: auto;
  border-radius: 5px;
}

.mashupdata .ant-collapse-content-box {
  margin-left: 2rem;
  margin-right: 2rem;
}

.activity-img img {
  height: auto;
  width: auto;
  border-radius: 5px;
}

.activity-img {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 1em 0;
  margin: 0 auto;
}

.br-5 {
  border-radius: 5px;
}

.steps-desc {
  height: 10.63rem;
  overflow-y: scroll;
}

.steps-img img {
  height: 229px;
  /* width: 383.6px; */
  width: 100%;
}

.steps-img-wrap {
  height: 229px;
  width: 384px;
}

.steps-img {
  background-color: #fcfeff;
}

.ptb-8 {
  padding: 0.5rem 0;
}

.ptb-4 {
  padding: 1.5rem 0;
}

.b-l {
  border-left: 1px solid #d8d8d8;
}
.titleCard {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  margin-top: 19px;
  margin-bottom: 19px;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 137% */

  color: rgba(0, 0, 0, 0.85);
}

.view-book-lib {
  margin: 32px 32px 0px 32px;
  padding-bottom: 32px;
}
.border-style {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
  padding: 24px;
}
.pb-18 {
  padding-bottom: 1.125rem;
}
.mtb-34 {
  margin: 2.125rem 0px;
}
.title {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}
.mtb-24 {
  margin: 1.5rem 0px;
}
.desc {
  font-family: Avenir;
  font-size: 16px;
  line-height: 22px;
}
.back-button {
  float: right;
  position: relative;
  bottom: 75px;
}

.featured-img {
  border-top: 0px !important;
  padding: 1rem;
}
.featured-img img {
  height: auto;
  width: 378px;
  border-radius: 5px;
}

.color-blue {
  color: #0076ff;
}
.color-red {
  color: #ff0000;
}
.text-area-style {
  color: #000000;
  background: white;
}
.b-l {
  border-left: 1px solid #d8d8d8;
}

.notification-card {
  box-shadow: 0 1px 6px rgba(74, 144, 226, 0.5) !important;
  border-radius: 5px !important;
  min-height: calc(100vh - 100px);
}

.notification-card .ant-card-body{
  padding: 0;
}

.notification-card .ant-menu-vertical{
  height: calc(100vh - 103px);
  overflow: scroll;
}

.notification-card .ant-menu-vertical .ant-menu-item {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  height: 55px;
  padding: 0.5rem 1rem;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.notification-card .ant-menu-vertical .ant-menu-item .notification-count{
  min-height: 27px;
  min-width: 48px;
  max-width: 48px;
  max-height: 27px;
  border-radius: 30px;
  background: #C8C8C8;
  color: #FFFFFF;
  padding: 0.25rem 1rem;
  font-size: 14px;
  line-height: normal;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.notification-card .notification-content {
  border-left: 1px solid #e8e8e8;
}

.notification-card .ant-menu-vertical .ant-menu-item-selected {
  background-color: #FFFFFF;
  border-right: 2px solid #1890FF;
}

.notification-card .ant-menu-vertical .ant-menu-item-selected .notification-count{
  background: #0084FF;
}

.notification-card .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none;
}

.single-notification {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 500;
  border-bottom: 1px solid #C8C8C8;
  height: 55px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.display-name {
  font-weight: 800;
  color: #000000;
}

.no-notifications-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #4A4A4A;
  font-size: 20px;
  margin-top: 24px;
}

.notification-table .anticon svg {
  margin: 0;
}
.lcApproval .ant-table-body td{
  padding:0;
}
.lcApproval td div{
  padding:16px
}
.lcApproval a,.lcApproval a:hover{
  text-decoration: none;
  color: inherit;
}
@media (min-width: 769px) {
  .backdrop {
    display: none !important;
  }

  .modal-style {
    display: none !important;
  }

  .top-strip {
    display: none !important;
  }
}

.top-strip {
  position: inherit;
  top: 0;
  right: 0;
  left: 0;
  background-color: #5432BA;
  padding: 16px;
}

.top-strip i {
  color: #FFC90D;
  font-size: 20px;
  padding-top: 4px;
}

.top-strip p {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  width: 100vw;
}

.modal-style {
  background-color: #FFFFFF;
  border-radius: 5px;
  margin: 0 auto;
  padding: 1rem;
  width: 280px;
  min-height: 233px;
}

.modal-style .modal-button {
  background: #3EC1F0;
  border-radius: 35px;
  font-family: Nunito, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  padding: 0 2rem;
}

.modal-style .modal-icon {
  background: #FFFFFF;
  font-size: 40px;
  margin-top: -55px;
  height: 61px;
  width: 61px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 50%;
  border: 6px solid #A8AAAC;
  color: #EC1A58;
}

.no-students {
  height: calc(100vh - 70px);
  overflow: scroll;
}

.student-image {
  border-radius: 50%;
  background-color: #D3E8FF;
  border: 1px solid #0078FF;
  height: 100px;
  width: 100px;
}

.student-image span {
  font-size: 20px;
  color: #0078FF;
}

.student-plus {
  border-radius: 50%;
  background-color: #c4c4c4;
  height: 100px;
  width: 100px;
}

.student-plus > .anticon svg,
.studentView .anticon svg {
  margin: 0;
}

.student-name-container {
  min-height: 65px;
  min-width: 150px;
  margin-right: 32px;
  padding-bottom: 16px;
}

.student-selected {
  border-bottom: 3px solid #0076ff;
}

.student-name-image {
  margin-right: 16px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  /* background: #FFFFFF; */
  /* border: 1.5px dashed #0076FF; */
  /* color: #1890FF; */
}

.student-selected > .student-name-image {
  background: #FFFFFF;
  border: 1px solid #0076FF;
}

.ChildrenImgFilter-det {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 5px;
}

.student-name-plus {
  margin-right: 16px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #e0e0e0;
  border: 1px dashed #C4C4C4;
}

.student-name-details span:first-child {
  font-weight: 800;
  font-size: 16px;
  color: #000000;
}

@media only screen and (max-width : 430px) {
  .student-name-container {
    padding: 16px;
  }

  .student-selected {
    border-left: 3px solid #0076ff;
    border-bottom: 0px;
  }

  .student-list {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

@media only screen and (max-height : 450px) {
  .no-students {
    height: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .student-list, .max-dev-student-name, .max-dev-Childmodal, .max-student-container, 
  .mbl-border-margin, .max-dev-carddet, .max-dev-ChildsTab {
    display: none!important;
  }
  .studentView {
    margin: 1rem 1rem 0rem;
  }
  .student-name-plus {
    margin-right: 10px;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1.5px dashed #0076FF;
  }
  .student-name-details span:first-child {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #0076FF;
  }
  .App, #root {
    background: #F2F2F2;
  }
  .student-name-container1 {
    min-height: 50px;
    min-width: auto;
    margin-right: 15px;
    padding-bottom: 10px;
  }
  .student-name-image {
    margin-right: 10px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: #FFFFFF;
    /* border: 1px dashed #333; */
    /* color: #333; */
  }
  .mbl-student-selected > .student-name-image {
    border: 1.5px solid #0076FF;
    color: #1890FF;
  }
  .mbl-student-selected > .mbl-stud-name {
    color: #1890FF;
    font-weight: 800;
  }
  .mbl-stud-name {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    white-space: nowrap;
  }
  .mbl-student-selected {
    border-bottom: 3px solid #0076FF!important;
  }
  .ant-modal-body {
    padding: 15px;
  }
  .mb-5px {
    margin-bottom: 5px!important;
  }
  .hint {
    font-family: Avenir!important;
    font-style: normal!important;
    font-weight: normal!important;
    font-size: 11px!important;
    color: #000000!important;
  }
  .mbl-dev-student-name {
    background: #FFFFFF;
    padding: 5px;
    width: 100%;
    overflow: scroll;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .mbl-studdet {
    padding: 0!important;
  }
  .mbl-m-0, .jounalTabDatepic {
    margin: 0!important;
  }
  .mbl-studname-head {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
  }
  .ant-card-body {
    padding: 10px;
    width: auto;
  }
  .mbl-child-title {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #828282;
  }
  .mbl-child-name {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }
  .feedbackText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0076FF;
  }
  .mbl-child-redtag {
    color: #F5222D;
    width: 64px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    border: 0;
  }
  .slider4 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .ant-tabs-bar {
    margin: 0px 16px 16px 16px;
    border-bottom: none;
  }
  .ant-tabs.ant-tabs-top.mbl-dev-ChildsTab.ant-tabs-line > div.ant-tabs-bar.ant-tabs-top-bar > div > div > div > div > div:nth-child(1) {
    margin: 5px 20px;
    border-bottom: 0.5px solid #BDBDBD;
  }
  .ant-tabs-nav {
    font-size: 16px;
  }
  .ant-tabs.ant-tabs-top.mbl-dev-ChildsTab.ant-tabs-line > div.ant-tabs-bar.ant-tabs-top-bar > div > div > div > div > div.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 165px!important;
    height: 3px!important;
    margin: 4px 20px!important;
  }
  .ant-tabs.ant-tabs-top.mbl-dev-ChildsTab.ant-tabs-line > div.ant-tabs-bar.ant-tabs-top-bar > div > div > div > div > div:nth-child(1) > div:nth-child(1) {
    margin: 0 15px 0 0;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #0076FF;
    font-weight: 800;
    font-size: 16px;
  }
  .ant-calendar-picker-container {
    left: 50px!important;
  }
  .site-custom-tab-bar {
    z-index: 200;
    background: #fafafa;
    height: 55px;
  }
}

@media only screen and (min-width: 576px) {
  .mbl-students-det, .mbl-dev-student-name, .mbl-dev-Childmodal, 
  .mbl-student-container, .mbl-dev-carddet, .mbl-dev-ChildsTab {
    display: none!important;
  }
  .ant-calendar {
    width: 100%;
  }
  .ant-calendar-disabled-cell > .journal-date {
    width: 50px;
  }
  .journal-date {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 50px;
    width: 50px;
    padding: 0 4px 8px 4px;
    font-size: 16px;
    /*border: 1px solid #0076FF;*/
  }
}

@media only screen and (max-width: 576px) {
    .max-weekChart-hr {
        display: none!important;
    }
}
@media only screen and (min-width: 576px) {
    .mbl-weekChart-hr {
        display: none!important;
    }
}
.weekPicker .ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: block !important;
  visibility: hidden;
}

.weekPicker .ant-calendar-week-number-header .ant-calendar-column-header-inner::after {
  visibility: visible;
  content: "Wn";
}

@media only screen and (max-width: 576px) {
    .max-weeklyempty-img, .max-weekly-updates, .max-weekupdate-name, .max-weeklyCards {
        display: none!important;
    }
    .mbl-weekpicker {
        -webkit-align-items: center!important;
                align-items: center!important;
        -webkit-justify-content: center!important;
                justify-content: center!important;
    }
    .mbl-weeklyempty {
        margin: 16px;
        padding: 16px;
    }
    .mbl-weeklyupdates-title {
        font-size: 16px;
        text-align: center;
        font-weight: 800;
    }
    .mbl-pd-10 {
        padding: 10px!important;
    }
    .mbl-weekupdate-name {
        font-weight: 800;
        font-size: 16px;
        color: #3C3C3C;
    }
    .ant-tabs-bar {
        margin: 0px 0px 16px 0px;
    }
    .mbl-weekCardsRow {
        margin: 0!important;
    }
    .mbl-viewAllCard {
        height: 218px;
        width: 211px;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        text-align: center;
        padding: 100px 75px!important;
        box-shadow: 0px 4px 16px rgb(0 0 0 / 10%)!important;
        border-radius: 10px!important;
    }
    .cardTab {
        /* height: 400px; */
        overflow: scroll;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .ant-tabs.ant-tabs-top.mbl-dev-ChildsTab.ant-tabs-line > div.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > div.ant-tabs-tabpane.ant-tabs-tabpane-active > div.pt-2 > div > div.ant-spin-nested-loading.w-100 > div > div > div.ant-tabs.ant-tabs-top.ant-tabs-line > div.ant-tabs-bar.ant-tabs-top-bar > div > span.ant-tabs-tab-next.ant-tabs-tab-arrow-show > span > i {
        color: #000;
    }
    .site-custom-tab-bar {
        z-index: 200;
        background: #fafafa;
        height: 55px;
      }
  }
@media only screen and (min-width: 576px) {
    .mbl-weeklyempty-img, .mbl-weekly-updates, .mbl-weekupdate-name, .mbl-weeklyCards {
        display: none!important;
    }
}
.parent-nav {
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(24, 144, 255, 0.1);
  height: 65px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 0 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.parent-card-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
}

@media only screen and (max-width: 576px) {
  .max-dev-parentSignup, .max-dev-parentSignupTitle, .max-dev-parentSignup-Btn,
  .max-dev-parent-note {
    display: none!important;
  }
  .mbl-mr-neg {
    margin: 0!important;
  }
  .mbl-dev-parentSignupTitle {
    color: #000!important;
    padding: 0 20px;
  }
  .mbl-txt-note {
    margin: 0 20px;
    margin-bottom: 7rem;
  }
  .mbl-noteTxt {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .mbl-dev-parentSignup-Btn {
    /* box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.08);
    background-color: white; */
    padding: 13px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .mbl-noLearners {
    padding: 24px;
  }
  .mbl-noLearners > img {
    width: 100%;
  }
  .mbl-childProf-Txt {
    font-size: 14px!important;
    font-weight: 500!important;
    color: #000!important;
  }
  .mbl-dob-icon {
    top: -2px!important;
    left: 100px!important;
    right: 0!important;
  }
  .mbl-dev-parent-note {
    margin-bottom: 4rem!important;
  }
  .mbl-parent-childProfile-mr {
    margin-bottom: 7rem!important;
  }
  .mbl-parentProfile-w-90 {
    width: 90%!important;
  }
}

@media only screen and (min-width: 576px) {
  .mbl-dev-parentSignup, .mbl-dev-parentSignupTitle, .mbl-dev-parentSignup-Btn, .mbl-dev-parent-note {
    display: none!important;
  }
}
.playlistCard {
  border-radius: 0.25rem;
  margin: 1rem;
  box-shadow: 2px 2px 9px 0px #d6d6d6;
}

.playlistCard .ant-card-body{
  height: 10.625rem;
}

.playlistCard .pTitle{
  text-transform: capitalize;
  color: black;
  font-weight: 500;
}

.linkedActivities .ant-list-item-content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center
}

.viewPlistCollapse {
  margin: 2rem 0rem 2rem;
}

.viewPlistCollapse label{
  color: rgba(0,0,0,0.85);
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin-top: 1rem;
  text-transform: uppercase;
}

.viewPlistCollapse .age_groups .borderful {
  border-right: 1px solid #000;
  padding: 0 0.65rem;
}

.viewPlistCollapse .age_groups .borderful:last-child {
  border-right: 0px;
}

.viewPlistCollapse .ant-row {
  margin-bottom: 1rem;
}

.plistViewTitle {
   box-shadow: 0 1px 0 1px rgba(0,0,0,0.05);
   border-radius: 4px;
}
.plistViewTitle .ant-card-head{
   border: 0px;
   padding-left: 16px;
}

.plistViewTitle .ant-card-head-title{
   color: rgba(0,0,0,0.85);
   font-size: 16px;
   text-transform: uppercase;
}

.plistViewTitle .ant-card-body{
   padding: 5px 32px 5px 16px;
}

.view-playlist .playlist-title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  text-transform: capitalize;
}

.linkedActivities .ant-list-item {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  padding: 10px;
  margin: 1rem;
  /* height: 50px; */
}

.linkedActivities .badge {
  border-radius: 4px;
  background-color: #36CFC9;
  color: #FFFFFF;
  height: 24px;
  width: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
}

.linkedActivities span {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin-left: 1rem;
}

.create-playlist-title .ant-card-body {
  padding: 2rem 1rem;
}

.createPlistCollapse {
  margin: 2rem 0;
}

.createPlistCollapse span {
  font-size: 16px;
}

.createPlistCollapse .interests-view-box {
  min-height: 40px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 8px;
}

.createPlistCollapse .link-act-lib svg{
  margin: 0
}

.createPlistCollapse .ant-list-item {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  padding: 10px;
  margin: 1rem;
}

.createPlistCollapse .badge {
  border-radius: 4px;
  background-color: #36CFC9;
  color: #FFFFFF;
  height: 24px;
  width: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
}

.createPlistCollapse .list-name {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin-left: 1rem;
}

.interest_category{
    padding: 10px;
    height: 64px;
    border-left: 3px solid  #1890FF;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.15);
    background-color: #FFFFFF;
    margin-left:20px;
    margin-bottom:10px;
    margin-top:10px;
    font-size: 16px;
}
.interest_category:nth-child(4n+1){
    margin-left:0
}
.interest-modal .ant-modal-footer,.pwd-modal .ant-modal-footer{
    border: 0;
}
.pwd-modal .ant-modal-body{
    padding-bottom: 0;
}
.hint{
    margin-bottom: 1.5em;
    font-size: 13px;
    color:#9B9B9B;
}
.titleColor{
    font-weight: 300;
    font-size: 16px;
}
.text-content{
    font-weight: 500;
    font-size: 18px;
}
.font18{
    font-size: 18px;
    font-weight: 500;
    color: #000000;
}
.edit-input{
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}
.modal-popup{
border: 1px dashed #4A90E2;	
border-radius: 2px;	
background-color: rgba(74,144,226,0.1);
}
.button-border{
    border: 1px solid #4A4A4A;;
    color:#4A4A4A;;
}
.pwd-modal{
    width: 430px !important;
}
.font-15{
font-size: 15px;
}
.font-12 {
    font-size: 12px;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.text-black {
    color: #333333;
}
.btn-txt-black {
    color: #000000;
}
.font-weight-800 {
    font-weight: 800;
}
.green-btn,.green-btn:hover,.green-btn:focus{
    background-color: #68B415;
    border:  #68B415;
}
.profile-image {
    max-width: 401px;
    max-height: 401px;
    position: relative;
}
.profile-image .delete-img {
    position: absolute;
    top: 8px;
    right: 8px;
    margin: 7px;
    padding: 0 0.3rem;
    background-color: #FFFFFF;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}
@media (max-width: 576px) {
    .max-dev-profile {
        display: none;
    }
    .dropdown-txt {
        color: #000000!important;
        font-weight: 800;
    }
    .mbl-profileBtn-txt {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.65);
    }
    .hint {
        margin-bottom: 0;
        font-size: 12px!important;
        color: #828282!important;
    }
    .pwd-show {
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        font-size: 10px;
        color: #000000;
    }
    .ant-modal-title {
        font-weight: 800;
        color: #000000;
    }
    .mbl-profile-img, .mbl-profile-img > img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    .profile-email-txt {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        color: #828282;
    }
    .floating-btn {
        border-radius: 50%;
        background: #F2F2F2;
        top: 60%;
        right: 30%;
        width: 25px;
        height: 25px;
    }
    .blue-btn {
        background: #0076FF;
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        color: #FFFFFF;
    }
    .mbl-edit-btn {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .w-130 {
        width: 130px;
    }
    .modal-upload .ant-upload {
        width: auto;
        height: auto;
    }
    .mb-6 {
        margin-bottom: 4rem!important;
    }
  }
@media (min-width: 576px) {
    .mbl-dev-profile {
      display: none;
    }
  }

  @media only screen and (max-width: 576px) {
    .max-dev-feedback-title, .table-full {
      display: none!important;
    }
    .Mbl-Feedback-breadcumb {
        color: #0076FF;
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
    }
    .mbl-main-div {
      margin: 1rem!important;
    }
    .mbl-Feedbackcard {
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
    .mbl-feedDates {
      border-radius: 12px;
      height: 20px;
      text-align: center;
    }
    .play-audio-btn {
      border: 1px solid #42C0EF;
    }
    .feedType-img {
      width: 42px;
      height: 42px;
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 10px;
    }
    .tagGeneral {
      font-size: 12px;
      color: #FF7A00;
      width: 65px;
      height: 20px;
      background: #FFEBD9;
      border-radius: 2px;
    }
    .mbl-feed-play-audio-btn {
      border: 1px solid #3EC0F1;
      box-sizing: border-box;
      padding: 5px 15px;
      width: auto;
      border-radius: 30px;
    }
  }

  @media only screen and (min-width: 576px) {
    .mbl-dev-feedback-title, .Mbl-feedback-empty, .mbl-FeedbacksCards {
      display: none!important;
    }
  }
.circle-indicator {
    width: 10px;
    height: 10px;
    background: transparent;
  }

  .workshop-btncolor {
    color: #ffffff!important;
    background: #0076FF!important;
    border-color: #0076FF!important;
  }

  .workshopupdated-box {
    height: 22px;
    width: 80px;
    background: #EA1F59;
    border: 1px solid #EA1F59;
    border-radius: 4px;
    color: #FFFFFF;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: 8px;
  }
  
  .ant-collapse-header {
    background: #e2f4ff;
    border-color: #e2f4ff;
    font-family: "Avenir", sans-serif !important;
    font-weight: 100 !important;
    font-size: 16px;
  }

  .pendingBtn-w250 {
    width: 250px;
  }
  
  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
  
  .box-sdw-rad {
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
  }
  
  .featured-img {
    border-top: 1px solid #e8e8e8;
    padding: 1rem;
  }
  
  .b-r {
    border-right: 1px solid #d8d8d8;
  }
  
  .pdl-2 {
    padding-left: 2rem;
  }
  
  .featured-img img,
  .steps-img img {
    height: auto;
    width: auto;
    border-radius: 5px;
  }
  
  .mashupdata .ant-collapse-content-box {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  
  .activity-img img {
    height: auto;
    width: auto;
    border-radius: 5px;
  }
  
  .activity-img {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 1em 0;
    margin: 0 auto;
  }
  
  .br-5 {
    border-radius: 5px;
  }
  
  .steps-desc {
    height: 10.63rem;
    overflow-y: scroll;
  }
  
  .steps-img img {
    height: 229px;
    /* width: 383.6px; */
    width: 100%;
  }
  
  .steps-img-wrap {
    height: 229px;
    width: 384px;
  }
  
  .steps-img {
    background-color: #fcfeff;
  }
  
  .ptb-8 {
    padding: 0.5rem 0;
  }
  
  .ptb-4 {
    padding: 1.5rem 0;
  }
  
  .b-l {
    border-left: 1px solid #d8d8d8;
  }
  .titleCard {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    text-align: center;
    margin-top: 19px;
    margin-bottom: 19px;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 137% */
  
    color: rgba(0, 0, 0, 0.85);
  }
  
  .view-book-lib {
    margin: 32px 32px 0px 32px;
    padding-bottom: 32px;
  }
  .border-style {
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
    padding: 24px;
  }
  .pb-18 {
    padding-bottom: 1.125rem;
  }
  .mtb-34 {
    margin: 2.125rem 0px;
  }
  .title {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
  .mtb-24 {
    margin: 1.5rem 0px;
  }
  .desc {
    font-family: Avenir;
    font-size: 16px;
    line-height: 22px;
    padding: 10px;
  }
  .long-description {
    font-family: Avenir;
    font-size: 16px;
    line-height: 22px;
    padding: 10px;
  }
  .back-button {
    float: right;
    position: relative;
    bottom: 75px;
  }
  
  .featured-img {
    border-top: 0px !important;
    padding: 1rem;
  }
  .featured-img img {
    height: auto;
    width: 378px;
    border-radius: 5px;
  }
  
  .color-blue {
    color: #0076ff;
  }
  .color-red {
    color: #ff0000;
  }
  .text-area-style {
    color: #000000;
    background: white;
  }
  .b-l {
    border-left: 1px solid #d8d8d8;
  }
  .btn-changeHover, .btn-changeHover:hover, .btn-changeHover:hover {
    color: #ffffff;
    border-color: #1890FF;
    border-radius: 4px;
    background: #1890FF;
  }
  .Changecomment-border {
    background: #FFF6D6;
  }
  .Changecomment-icon {
    color: #F8C947;
    font-size: 24px;
    text-align: center;
  }
  .Changecomment {
    text-align: center;
    font-size: 16px;
    margin: 4px;
  }
  .contentcreateLab {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 147.2%;
    color: #000000;
  }
  .tagStatusPending {
    background: #FFF7E6;
    color: #FA8C16;
    border: 1px solid #FFD591;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .tagStatusRejected {
    background: #FFDCE6;
    color: #EA1F59;
    border: 1px solid #EA1F59;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .tagStatusApproved {
    background: #EEFFDB;
    color: #68B415;
    border: 1px solid #68B415;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .registeredstudBtn-w250, .registeredstudBtn-w250:hover, .registeredstudBtn-w250:focus {
    /* width: 250px; */
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #0076FF;
    border: 1px solid #0076FF;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .fireimg {
    padding-left: 5px;
    padding-right: 5px;
  }
  .plusmoreTag {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
  }
  .featuredTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.85);
  }
  .workTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    color: #FFFFFF;
  }
  .sort {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #4A4A4A;
  }
  .sort-active {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #0076FF;
  }
  .dateTime {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
  }
  .user-profile-img {
    border-radius: 50%;
    width: 65px;
    height: 65px;
  }
  .featuredUpcomingworkshop {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
    /* background: #364d79; */
  }

  .parentWorkview {
    color: #57479C;
  }

  .carousalImg {
    width: 100%;
  }

  .carousalImg1 {
    width: 100%;
    cursor: pointer;
  }

  .caroactive, .carousalImg1:active {
    background: #FFFFFF;
    width: 100%;
    border: 1px solid #4F4F4F;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    padding: 3px;
  }

  .heading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }
  .dateText {
    color: #828282;
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
  .workshoptypeText {
    color: #000000;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .WorkshopViewTitleText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
  }
  .workRow {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: start;
            justify-content: start;
    /* float: left; */
    width: 100%;
    margin-bottom: 10px;
  }
  .workCol {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    float: left;
    margin-right: 10px;
    padding-right: 10px;
  }
  .hurryText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    -webkit-align-items: center;
            align-items: center;
    color: #EA1F59;
  }
  .dateDiv {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 5px;
    background: #F9F9F9;
    border-radius: 5px;
  }
  .workDate {
    position: static;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    color: #333333;
    -webkit-flex: none;
            flex: none;
    -webkit-order: 0;
            order: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    margin: 5px 0px;
  }
  .workTime {
    position: static;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    color: #828282;
    -webkit-flex: none;
            flex: none;
    -webkit-order: 1;
            order: 1;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    margin: 5px 0px;
  }
  .reqText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    text-transform: capitalize;
    color: #333333;
    margin-top: 10px;
  }
  .comContentDiv {
    position: relative;
    float: left;
  }
  .ContentDiv {
    position: relative;
    width: auto;
    height: auto;
    border-radius: 10px;
    padding: 8px;
    text-align: center;
    box-shadow: 10px 10px #FFF;
  }
  .ContentDiv1 {
    position: relative;
    width: auto;
    height: auto;
    border-radius: 4px;
    margin-right: 10px;
    text-align: center;
    box-shadow: 10px 10px #FFF;
  }
  .Conhead1 {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    color: #000000;
  }
  .bioDetail {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
    text-align: start;
  }
  .ContentImg {
    margin: 10px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  .contentName {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #fff;
  }
  .arrowIcon {
    color: #333333;
  }
  .btn-addCart, .btn-addCart:hover, .btn-addCart:focus {
    background: #57479C;
    border-radius: 5px;
    color: #fff;
    border: none;
    width: 200px;
  }
  .btn-inProgress {
    background: #BDBDBD!important;
    color: #fff!important;
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    border-radius: 5px;
  }
  .contentCreat {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    color: #000000;
  }
  .RegText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: left;
    color: #000000;
  }
  .regTex {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
  }
  .regDateTitleText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333333;
  }
  .regDateText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #828282;
  }
  .btn-register, .btn-register:hover, .btn-register:focus {
    width: 100%;
    background: #EA1F59;
    border-radius: 4px;
    border: none;
  }
  .carttotal-text {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 161.1%;
    color: #000000;
    float: left;
  }
  .carttotal-amt {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 161.1%;
    color: #000000;
    float: right;
  }
  .purchase-heading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 161.1%;
    color: #000000;
  }
  .cartitem-text {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 161.1%;
    color: #000000;
    float: left;
  }
  .cartitem-amt {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 161.1%;
    color: #000000;
    float: right;
  }
  .purchase-row {
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .workcartimg {
    width: auto;
    height: 200px;
  }
  .selectchildtext {
    font-family: Avenir;
    font-size: 14px;
    -webkit-align-items: center;
            align-items: center;
    color: #000000;
  }
  .editicon {
    color: #0076FF;
    font-size: 16px;
    position: absolute;
    margin-left: 10px;
    cursor: pointer;
  }
  .linebar {
    border: 1px solid #828282;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin: 0px 15px;
  }
  .btns-cart {
    float: right;
  }
  .btn-save {
    cursor: pointer;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  .btn-save:hover, .btn-save:focus {
    color: rgb(66, 192, 239)!important;
  }
  .btn-del {
    cursor: pointer;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  .btn-del:hover, .btn-del:focus {
    color: #EA1F59!important;
  }
  .featureTitle {
    border-radius: 0px 4px 0px 0px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  .preqTextDiv {
    position: relative;
    background: #FFF6D6;
    border-radius: 2px;
  }
  .preqText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    text-transform: capitalize;
    color: #333333;
    padding: 5px;
  }
  .preqTextBord {
    background: #F8C947;
    border: 2px solid #F8C947;
    margin-right: 5px;
  }
  .joinWorkshop-div {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
    padding: 15px 30px;
  }
  .joinContentText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
  }
  .topbannerImg {
    max-height: 100%;
    min-width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
    border-radius: 10px;
  }
  @media (min-width: 1140px) {
    .search-parent {
      width: 400px;
    }
  }
  .featuredTitleLen {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: end;
            justify-content: end;
    margin-top: 5px;
    -webkit-align-items: center;
            align-items: center;
  }
  .featuredEmpty {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
  @media (max-width: 1280px) {
    .ContentImg {
      margin: 8px;
      width: 65px;
      height: 65px;
      border-radius: 50%;
    }
    .contentName {
      font-size: 10px;
    }
  }
  .min-w-300 {
    min-width: 425px;
    @media (min-width: 350px) {
      min-width: 325px;
    }
    @media (min-width: 1100px) {
      min-width: 425px;
    }
  }
  .w-300 {
    width: 425px;
    scrollbar-width: none;
    @media (min-width: 350px) {
      width: 325px;
    }
    @media (min-width: 1100px) {
      width: 425px;
    }
  }
  .w-400 {
    width: 400px;
  }
  .meetingBtn {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    color: #0076FF;
    border: 1px solid #0076FF;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .primaryHostText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
  .notAttendedTag {
    color: #EA1F59;
    background: #FFDDE4;
    border: 1px solid #EA1F59;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    text-align: center;
}
.TaxText {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #828282;
}

@media only screen and (max-width: 576px) {
  .max-dev-d-none, .max-dev-topbanner {
    display: none!important;
  }
  .createActivity {
    background: transparent!important;
  }
  .mbl-workshopList-h {
    /* height: calc(100vh - 215px)!important; */
    overflow: unset;
  }
  .tabscroll {
    height: auto!important;
    overflow: unset!important;
  }
  .mbl-padding-0 {
    padding: 0!important;
  }
  .mbl-pl-none {
    padding-left: 15px!important;
  }
  .mbl-dev-w-search {
    padding: 0!important;
  }
  .search-parent::-webkit-input-placeholder {
    font-size: 14px;
  }
  .search-parent::placeholder {
    font-size: 14px;
  }
  .topbannerImg {
    border-radius: 0;
  }
  .mbl-min-w-300 {
    min-width: 0px;
  }
  .mbl-w-90 {
    width: 90px!important;
  }
  .mbl-w-tab {
    text-align: center;
    height: 35px;
  }
  .mbl-w-tab.active {
    border-bottom: 3px solid #0076FF;
  }
  .mbl-w-tab > span {
    color: #828282;
    font-weight: normal;
    font-size: 16px;
  }
  .mbl-w-tab.active > div {
    color: #0076FF;
    font-weight: 800;
  }
  body {
    overflow-y: unset!important;
  }
  .mbl-nav-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 200;
    background: #fafafa;
    height: 55px;
    padding: 16px;
  }
}

@media only screen and (min-width: 576px) {
  .mbl-dev-w-search, .mbl-dev-d-none,
  .mbl-dev-topbanner {
    display: none!important;
  }
}
.ondatetime {
	font-size: 1.3rem;
	width: 100%;
}
@media (max-width: 1439px) {
	.ondatetime {
		font-size: 1.1rem!important;
		width: 100%!important;
	}
	.DayPicker-Month {
		width: 100%!important;
	}
	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
		padding: 0.5em!important;
	}
}
.fonttime1 {
	letter-spacing: 0px;
	text-align: left;
	font-family: Avenir;
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	color: #000000;
}
.fontrepeat {
	font-family: Avenir;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	color: #666666;
}
.fontrepeat-disable {
	opacity: 50%;
}
.nc-buttonSelected {
	color: #0076ff;
	border-color: #0076ff;
}
.DayPicker-Day--today {
	color: #0076ff;
	font-weight: 700;
}
.DayPicker-Months {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: center;
	        justify-content: center;
	background: #f8fbff;
}

.DayPicker-wrapper {
	position: relative;
	width: 100%;
	-webkit-flex-direction: row;
	        flex-direction: row;
	padding-bottom: 1em;
	-webkit-user-select: none;
	user-select: none;
}
.DayPicker-Month {
	display: table;
	margin: 0 1em;
	margin-top: 0px;
	margin-top: 1em;
	border-spacing: 0;
	border-collapse: collapse;
	width: 80%;
	-webkit-user-select: none;
	user-select: none;
}
.DayPicker-NavButton--prev {
	margin-right: 1.5em;
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBkYXRhLW5hbWU9IjEiIGlkPSJfMSI+PHBhdGggZD0iTTM1Myw0NTBhMTUsMTUsMCwwLDEtMTAuNjEtNC4zOUwxNTcuNSwyNjAuNzFhMTUsMTUsMCwwLDEsMC0yMS4yMUwzNDIuMzksNTQuNmExNSwxNSwwLDEsMSwyMS4yMiwyMS4yMUwxODkuMzIsMjUwLjEsMzYzLjYxLDQyNC4zOUExNSwxNSwwLDAsMSwzNTMsNDUwWiIvPjwvZz48L3N2Zz4=');
}
.DayPicker-NavButton--next {
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBkYXRhLW5hbWU9IjEiIGlkPSJfMSI+PHBhdGggZD0iTTIwMi4xLDQ1MGExNSwxNSwwLDAsMS0xMC42LTI1LjYxTDM2NS43OSwyNTAuMSwxOTEuNSw3NS44MUExNSwxNSwwLDAsMSwyMTIuNzEsNTQuNmwxODQuOSwxODQuOWExNSwxNSwwLDAsMSwwLDIxLjIxbC0xODQuOSwxODQuOUExNSwxNSwwLDAsMSwyMDIuMSw0NTBaIi8+PC9nPjwvc3ZnPg==');
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: #0076ff;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	position: relative;
	background-color: #0076ff;
	color: #fff;
}
.DayPicker-Day {
	display: table-cell;
	padding: 1em;
	border-radius: 50%;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
    font-style: normal;
    text-align: center;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    position: absolute;
    display: inline-block;
    line-height: 46px;
    vertical-align: top;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 16px;
  }
  
  .age {
    display: inline-block;
  }
  
  .age .ant-select {
    width: auto;
    min-width: 100px;
  }
  
  .age .ant-select .ant-select-selection--single {
    border: none;
    box-shadow: none;
    margin-right: 30px;
  }
  
  .save-activity-btn {
    float: right;
    position: relative;
    bottom: 7em;
  }
  
  .btn-wrapper {
    float: right;
    margin-top: 16px;
  }
  
  .btn-wrapper button {
    height: 34px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 2px;
  }
  
  .btn-wrapper button:first-child {
    margin-right: 8px;
  }
  
  .create-steps {
    padding: 20px;
    background-color: #fcfeff;
    border: 1px solid rgba(0, 118, 255, 0.2);
    border-radius: 4px;
  }
  
  .featuredImage .featured-img img {
    width: 378px;
    border-radius: 5px;
  }
  
  .featuredImage .featured-img {
    padding: 0;
  }
  
  .create-steps .ant-upload.ant-upload-drag .ant-upload-btn {
    min-height: 170px;
  }
  
  .color, .color .ant-select-arrow {
    color: #1890ff;
  }
  
  .image_details {
    clear: both;
  }
  
  .create-activity .ant-collapse-header {
    background: #e2f4ff;
    border-color: #e2f4ff;
    font-family: "Avenir", sans-serif !important;
    font-weight: 100 !important;
    font-size: 16px;
  }
  
  .addmorebutton {
    padding: 5px;
    border: 1px solid #0076ff;
    color: #0076ff;
  }
  
  .border-color-blue {
    border-color: #bae7ff !important;
  }
  
  .create-activity .ant-collapse {
    border: none;
    background: transparent;
  }
  
  .activityPageScroll {
    /* height: calc( 100vh - 190px); */
    overflow: scroll;
  }
  
  .tabscroll {
    height: calc(100vh - 215px);
    overflow: scroll;
  }
  
  .min-300 {
    max-width: 300px;
  }
  
  .ActivityBorder th {
    height: 3rem;
  }
  
  .ActivityBorder tr {
    margin: 0.5rem;
  }
  
  .btn-wrapper .anticon svg {
    margin: 0;
  }
  
  .step-cancel, .step-cancel:hover {
    color: #9B9B9B;
    border: 1px solid #9B9B9B;
  }
  .text-opt{
    font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 137% */
  
  color: #9B9B9B
  }

  .workshop-approve, .workshop-approve:hover, .workshop-approve:focus {
    color: #ffffff;
    border-radius: 4px;
    background: #68B415;
  }
  

@media only screen and (max-width: 576px) {
    .max-dev-workshopView {
      display: none!important;
    }
    .mbl-dev-workshopView {
        background: #fafafa;
    }
    .workshoptypeText, .hurryText {
      font-size: 12px;
    }
    .title {
      font-size: 14px;
      font-weight: 800!important;
      color: #828282;
    }
    .desc {
      color: #333!important;
    }
    .long-description {
      font-size: 14px;
      padding: 0;
    }
    .contentCreat {
      font-weight: 800;
      font-size: 14px;
      color: #828282;
    }
    .ContentDiv {
      width: 85px;
      height: 85px;
    }
    .ContentImg {
      margin: 4px;
      width: 50px;
      height: 50px;
    }
    .mbl-content-creator {
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
    .bioDetail {
      font-size: 12px;
    }
    .mbl-bottom-btn {
      box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.08);
      width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 12px;
    }
    .mbl-bottom-btn > h6 {
      color: #EA1F59;
    }
    .btn-inProgress, .btn-addCart, .btn-addCart:hover, .btn-addCart:focus {
      width: 328px;
      font-size: 16px;
    }
    .mt-mb-6 {
      margin: 3.5rem 0 6.5rem 0;
    }
    .mbl-listImg {
      margin-top: .5rem;
    }
    .sliderMainImg {
      width: 100%;
      height: 184px;
    }
    .mbl-list-img {
      width: 100px!important;
      height: 60px!important;
    }
    .min-w-80per {
      min-width: 80%!important;
    } 
    .w-80per {
      width: 80%!important;
    }
    .RegText {
      font-size: 14px;
    }
    .registerTag {
      font-size: 12px;
      background: #F0FFE0!important;
      color: #68B415!important;
      border: 1px solid #68B415!important;
    }
    .min-w-200 {
      min-width: 200px;
    }
    .w-200 {
      width: 200px;
    }
    .mbl-h-videoPlayer {
      height: 165px!important;
    }
  }
@media only screen and (min-width: 576px) {
    .mbl-dev-workshopView, .mbl-scroll-smallimg {
      display: none!important;
    }
    .mbl-h-videoPlayer {
      height: 370px!important;
    }
  }


.successMain {
    width: 620px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.payment-title {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 37px;
    color: #000000;
    text-align: center;
}
.payment-para {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #000000;
}
.workcartimg {
    border-radius: 5px;
    object-fit: cover;
}
.paymentLoading {
    font-size: 18px;
    color: #ffffff;
    font-weight: 500;
    margin: 20px;
}

b {
    font-weight: 800;
  }
@media only screen and (max-width: 576px) {
    .max-dev-cart, .max-dev-emptycart {
      display: none!important;
    }
    .padding-12 {
        padding: 12px!important;
    }
    .purchase-heading {
        font-size: 20px;
    }
    .selectchildtext, .border-bottom {
        margin-top: .5rem!important;
    }
    .heading {
        font-weight: 800!important;
        font-size: 20px!important;
    }
    .hurryText {
        font-size: 14px!important;
    }
    .btn-save {
        font-weight: 500;
        font-size: 18px;
        color: #0076FF!important;
    }
    .btn-del {
        font-weight: 500;
        font-size: 18px;
        color: #EA1F59!important;
    }
    .mb-6rem {
        margin-bottom: 6rem!important;
    }
    .payment-totalAmount {
        font-size: 24px;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        color: #000000;
    }
    .payment-purchaseSummary {
        cursor: pointer;
        font-size: 14px;
        line-height: 161.1%;
        color: #0076FF;
    }
    .btn-payment {
        font-size: 14px;
        font-weight: 800;
    }
}

@media only screen and (min-width: 576px) {
    .mbl-dev-cart, .mbl-dev-emptycart {
      display: none!important;
    }
}
.reg_studTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
}
.breadcrumbCursor {
    cursor: pointer!important;
}
.breadcrumbCursor:hover, .breadcrumbCursor:focus {
    cursor: pointer!important;
    color: #40a9ff!important;
}
.registeredBreadcrumb {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
}
.registerTag {
    background: rgb(246, 255, 237) none repeat scroll 0% 0%;
    color: #52C41A;
    border: 1px solid #B7EB8F;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    text-align: center;
}
.pendingTag {
    color: #FA8C16;
    background: #FFF7E6;
    border: 1px solid #FFD591;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    text-align: center;
}
.notAttendedTag {
    color: #EA1F59;
    background: #FFDCE6;
    border: 1px solid #EA1F59;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    text-align: center;
}
.ant-tabs-tab.tab-color {
    color: #333!important;
}
.ant-tabs-tab.tab-color1 {
    color: #828282!important;
}
.meetingText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 2px!important;
    margin-top: 2px!important;
}
.conText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 2px!important;
    margin-top: 2px!important;
    color: #333333;
}
/* body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
} */
.text-gray{
  color: #9B9B9B;
}
/* .ant-modal-close-x{
  display: none;
} */
