@media (min-width: 769px) {
  .backdrop {
    display: none !important;
  }

  .modal-style {
    display: none !important;
  }

  .top-strip {
    display: none !important;
  }
}

.top-strip {
  position: inherit;
  top: 0;
  right: 0;
  left: 0;
  background-color: #5432BA;
  padding: 16px;
}

.top-strip i {
  color: #FFC90D;
  font-size: 20px;
  padding-top: 4px;
}

.top-strip p {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.modal-style {
  background-color: #FFFFFF;
  border-radius: 5px;
  margin: 0 auto;
  padding: 1rem;
  width: 280px;
  min-height: 233px;
}

.modal-style .modal-button {
  background: #3EC1F0;
  border-radius: 35px;
  font-family: Nunito, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  padding: 0 2rem;
}

.modal-style .modal-icon {
  background: #FFFFFF;
  font-size: 40px;
  margin-top: -55px;
  height: 61px;
  width: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 6px solid #A8AAAC;
  color: #EC1A58;
}
