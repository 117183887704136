.parent-nav {
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(24, 144, 255, 0.1);
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.parent-card-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

@media only screen and (max-width: 576px) {
  .max-dev-parentSignup, .max-dev-parentSignupTitle, .max-dev-parentSignup-Btn,
  .max-dev-parent-note {
    display: none!important;
  }
  .mbl-mr-neg {
    margin: 0!important;
  }
  .mbl-dev-parentSignupTitle {
    color: #000!important;
    padding: 0 20px;
  }
  .mbl-txt-note {
    margin: 0 20px;
    margin-bottom: 7rem;
  }
  .mbl-noteTxt {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .mbl-dev-parentSignup-Btn {
    /* box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.08);
    background-color: white; */
    padding: 13px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .mbl-noLearners {
    padding: 24px;
  }
  .mbl-noLearners > img {
    width: 100%;
  }
  .mbl-childProf-Txt {
    font-size: 14px!important;
    font-weight: 500!important;
    color: #000!important;
  }
  .mbl-dob-icon {
    top: -2px!important;
    left: 100px!important;
    right: 0!important;
  }
  .mbl-dev-parent-note {
    margin-bottom: 4rem!important;
  }
  .mbl-parent-childProfile-mr {
    margin-bottom: 7rem!important;
  }
  .mbl-parentProfile-w-90 {
    width: 90%!important;
  }
}

@media only screen and (min-width: 576px) {
  .mbl-dev-parentSignup, .mbl-dev-parentSignupTitle, .mbl-dev-parentSignup-Btn, .mbl-dev-parent-note {
    display: none!important;
  }
}