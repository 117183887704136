.ondatetime {
	font-size: 1.3rem;
	width: 100%;
}
@media (max-width: 1439px) {
	.ondatetime {
		font-size: 1.1rem!important;
		width: 100%!important;
	}
	.DayPicker-Month {
		width: 100%!important;
	}
	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
		padding: 0.5em!important;
	}
}
.fonttime1 {
	letter-spacing: 0px;
	text-align: left;
	font-family: Avenir;
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	color: #000000;
}
.fontrepeat {
	font-family: Avenir;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
	color: #666666;
}
.fontrepeat-disable {
	opacity: 50%;
}
.nc-buttonSelected {
	color: #0076ff;
	border-color: #0076ff;
}
.DayPicker-Day--today {
	color: #0076ff;
	font-weight: 700;
}
.DayPicker-Months {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background: #f8fbff;
}

.DayPicker-wrapper {
	position: relative;
	width: 100%;
	flex-direction: row;
	padding-bottom: 1em;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.DayPicker-Month {
	display: table;
	margin: 0 1em;
	margin-top: 0px;
	margin-top: 1em;
	border-spacing: 0;
	border-collapse: collapse;
	width: 80%;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.DayPicker-NavButton--prev {
	margin-right: 1.5em;
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBkYXRhLW5hbWU9IjEiIGlkPSJfMSI+PHBhdGggZD0iTTM1Myw0NTBhMTUsMTUsMCwwLDEtMTAuNjEtNC4zOUwxNTcuNSwyNjAuNzFhMTUsMTUsMCwwLDEsMC0yMS4yMUwzNDIuMzksNTQuNmExNSwxNSwwLDEsMSwyMS4yMiwyMS4yMUwxODkuMzIsMjUwLjEsMzYzLjYxLDQyNC4zOUExNSwxNSwwLDAsMSwzNTMsNDUwWiIvPjwvZz48L3N2Zz4=');
}
.DayPicker-NavButton--next {
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBkYXRhLW5hbWU9IjEiIGlkPSJfMSI+PHBhdGggZD0iTTIwMi4xLDQ1MGExNSwxNSwwLDAsMS0xMC42LTI1LjYxTDM2NS43OSwyNTAuMSwxOTEuNSw3NS44MUExNSwxNSwwLDAsMSwyMTIuNzEsNTQuNmwxODQuOSwxODQuOWExNSwxNSwwLDAsMSwwLDIxLjIxbC0xODQuOSwxODQuOUExNSwxNSwwLDAsMSwyMDIuMSw0NTBaIi8+PC9nPjwvc3ZnPg==');
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: #0076ff;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	position: relative;
	background-color: #0076ff;
	color: #fff;
}
.DayPicker-Day {
	display: table-cell;
	padding: 1em;
	border-radius: 50%;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
}
