@import '~antd/dist/antd.css';
@import url("https://fonts.googleapis.com/css?family=Avenir:400,500,700,900");
/* @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900'); */


/* .App {
  text-align: center;
}

.App-logo {
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
}

.App-title {
  font-size: 1.5em;
} */

*{
  font-family: 'Avenir', sans-serif;
  -webkit-font-smoothing: antialiased
}
.App,#root{
  background: #F0F3F7;
  font-family: 'Avenir', sans-serif;
  min-height: 100%;
}

@media only screen and (max-width: 576px) {
 .App, #root {
    background: #fafafa!important;
  }
}

.logo{
  /*height: 100px;*/
  /*width: 107.69px;*/
  /*box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  /* margin: 24px; */
  display: block;
  margin: 20px auto 50px auto;
}
.flex-wrap{
  display: flex;
  padding: 1em 0em;
  border-top: 1px solid rgba(0,0,0,0.09);
}

.hr-line{
  padding: 1em 0em;
  border-top: 1px solid rgba(0,0,0,0.09);
}
/* .p-t{
  padding-top: 1.5em;
} */
.f-r{
  float:right;
}
.mtb-20{
  margin-bottom: 20px;
}
.font14{
  font-size: 16px;
}
.font16 {
  font-size: 16px;
}
.vh-100{
  height: 100vh;
}
.font-weight{
  font-weight: 300;
}
.float-right {
  float: right;
}
.opacity-black-5{
background: rgba(0,0,0,0.6);
}
.absolute-loading{
  position: absolute;
  bottom:0;
  top:0;
  left:0;
  right:0
}
.opacity-white-5{
  background: rgba(255,255,255,0.6);
}
.opacity-transparent{
  background: transparent;
}

.z-index-1{
  z-index: 1;
}
.z-index-100{
  z-index: 99;
}
.loading{
    font-size: 40px;
    color: white;
}
.opacity-white-5 .loading{
  color:#0076FF;
}
.color-blue-6{
  color:#1890ff;
}
.border-right-grey{
  border-right: 1px solid #ccc;
}
.ant-collapse-item > .ant-collapse-header{
  display: flex;
  justify-content: space-evenly;
}

.anticon-right svg{
  float:right;
}

.linkedActivities > .ant-btn > .anticon > svg {
  margin: 0;
}
.uparrow{
  padding-bottom: 2.5rem;
}
@font-face {
  font-family: "Avenir";
  src: url("./static/fonts/Avenir-Black-03.ttf") format("truetype");
  font-weight: 900;
}
@font-face{
  font-family: "Avenir";
  src: url('./static//fonts/Avenir-Medium-09.ttf') format("truetype");
  font-weight: 500;
}
@font-face{
  font-family: "Avenir";
  src: url('./static/fonts/Avenir-Roman-12.ttf') format('truetype');
  font-weight: 100;
}
@font-face{
  font-family: "Avenir";
  src: url('./static/fonts/Avenir-Heavy-05.ttf') format('truetype');
  font-weight: 800;
}
@font-face{
  font-family: "Avenir";
  src: url('./static/fonts/Avenir-Book-01.ttf') format('truetype');
  font-weight: 700;
}
.FontAvenirRoman{
  font-family: "Avenir" !important;
  font-weight: 100 !important;
}
.FontAvenirBlack{
  font-family: 'Avenir' !important;
  font-weight: 900 !important;
}
.FontAvenirHeavy{
  font-family: 'Avenir' !important;
  font-weight: 800 !important;
}
.FontAvenirBook{
  font-family: 'Avenir' !important;
  font-weight: 700 !important;
}
.FontAvenirMedium{
  font-family: 'Avenir' !important;
  font-weight: 500!important;
}
.font-16{
  font-size: 16px;
}
.font-20{
  font-size: 20px;
}
.overflow-scroll{
  overflow:scroll
}
.font-14{
  font-size: 14px;
}
.font-18{
  font-size:18px;
}
.font-19{
  font-size: 19px !important;
}
.font-12{
  font-size: 12px;
}
.cursor-pointer{
  cursor: pointer;
}
.z-index-999{
  z-index: 1000;
}
.height-80{
  height: 80px;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
}
.height-40{
  height: 40px;overflow:scroll;overflow-y:auto;  overflow-x: hidden;

}
      .height-80::-webkit-scrollbar,.height-40::-webkit-scrollbar {
        /* display: none; */
      }
.ant-tabs-nav .ant-tabs-tab:last-child{
  /* float: right; */
}
.lc-onboarding__card .ant-checkbox-wrapper {
  display: flex;
  align-items: flex-start;

}
.lc-onboarding__card .ant-checkbox-inner{
  margin-top: 0.25rem;
}
.ant-tabs-nav{
  width: 100%;
}
.TabActivities .ant-modal-footer
  {border-top: 1px solid #e8e8e8;
    padding: 0;
    text-align: right;
    margin-left: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 30px;
    border-radius: 0 0 4px 4px;
  }
  .fontColor-blue{
    color: #0076FF;
  }
  .pointer-cursor{
    cursor: pointer;
  }
  .pointer-normal{
    cursor: unset !important;
  }
  .featuredImage .ant-upload-list-item-done{
    display: none;
  }
  .activity-image .ant-upload-list-item-done{
    display: none;
  }
  .featuredImageUpload .ant-upload-list-item-done{
    display: none;
  }
  .pdf-upload .ant-upload-list-item-done{
    display: none;
  }
  .pdf-upload .delete-img {
    position: absolute;
    top: 0px;
    right: 1%;
    margin: 10px;
    padding: 0px 0.3rem;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .activity-steps-images {
    margin: 16px 0;
  }
  .activity-steps-images .delete-img {
    position: absolute;
    top: 0px;
    right: 10%;
    margin: 10px;
    padding: 0px 0.3rem;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  @media (min-width: 1500px){
    .App.Container{
      max-width: 1500px;
      margin: 0 auto;
    }
  }

  .studentsDataTable .pagination {
    float: right;
  }
  .studentView {
    margin: 2rem 2rem 0rem;
  }
  .studentView .ant-card-body .ant-card-grid{
    padding: 0.625rem
  }

  .close-icon{
    width:18px;
    height: 18px;
  }
  /* .ant-calendar-date.present::after {
     content: '⋅';
    font-size: 2rem;
    position: relative;
    top: 14px;
    left: -14px;
    color: green;
  } */
.datePicker.ant-calendar-picker,
.datePicker.ant-calendar-picker input,
.datePicker.ant-calendar-picker div,
.datePicker.ant-calendar-picker i {
  visibility: hidden;
  width: 0px;
  border: 0px;
  height: 0px;
  padding: 0px;
  margin: 0px;
}
.ant-btn{
  font-weight: 500;
}

#errors {
  color: red;
}
.react-bs-table-no-data {
  text-align: center;
}
#has-error {
  border-color: #f5222d;
}
#has-error-label {
  color: #f5222d !important;
  border-color: #f5222d !important;
}
.has-error-ma .ant-collapse-header {
  color: #f5222d !important;
}
.has-error, .has-error .ant-upload.ant-upload-drag {
  border: 1px dashed #f5222d !important;
}
.linksIn404 {
  color: #0076ff;
  text-decoration: underline;
}
.linksIn404:hover {
  cursor: pointer;
}
.requiredAsterisk::after{
  display: inline-block;
  content: '*';
  font-family: SimSun;
  font-size: 14px;
  color: #f5222d;
  position: relative;
  top: -5px;
  margin-left: 2px;
}

.noNetError .ant-spin-text{
color: red;
font-weight: 500;
font-size: 20px;
}

.featuredImageUpload {
  height: 197px;
}
.featured-img-upload .delete-img {
  position: absolute;
  top: 0px;
  right: 25px;
  margin: 10px;
  padding: 0px 0.3rem;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.index-number {
  position: absolute;
  top: 0px;
  left: 25px;
  margin: 10px;
  padding: 0px 0.3rem;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 800;
  border-radius: 50%;
}

.delete-img-profile {
  position: absolute;
  top: 0px;
  right: 45px;
  margin: 10px;
  padding: 0px 0.3rem;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.featured-img-upload{
  border-top: 0px !important;
  padding: 0 1rem;
  max-width: 100%;
}
.featured-img-upload img{
  width: auto;
  border-radius: 5px;
  height: auto;
}

.ant-calendar-footer-extra {
  text-align: right;
}

.text-transform-none {
  text-transform: none !important;
}
.stepsImages-filled-outline .outline-circle{
  display: block;
}
.outline-circle{
  display: none;
  width: 14px;
    height: 14px;
    background: #52C41A;
    border-radius: 50%;

}
.stepsImages-filled {
  background: #68B415;
  border: 1px solid #68B415;
  box-sizing: border-box;
  border-radius: 4px;
  color: #FFFFFF;
}

.stepsImages-empty {
  background: rgba(104, 180, 21, 0.15);
  border: 1px solid #68B415;
  box-sizing: border-box;
  border-radius: 4px;
  color: #68B415;
}
.stepsImages-empty-outline,.stepsImages-filled-outline{

background: rgba(230, 247, 255, 0.1);
border: 1px solid #BAE7FF;
box-sizing: border-box;
border-radius: 4px;
}
.stepsImages-empty-outline > div,.stepsImages-filled-outline > div{
  font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */

color: rgba(0, 0, 0, 0.65);
}
.stepsImages-empty-outline svg, .stepsImages-filled-outline svg{
  width: 12px;
}

.stepsImages-empty > .anticon > svg {
  margin: 0;
}

.stepsImages-filled > .anticon > svg {
  margin: 0;
}

.contents-table img {
  margin-right: 8px;
}

.contents-table img:last-child {
  margin-right: 0px;
}

.table-full table {
  box-shadow: 0px 1px 6px rgba(74, 144, 226, 0.5);
}

.table-full tbody td {
  vertical-align: top;
}

.table-full .anticon svg {
  margin: 0;
}

.table-row {
  background-color: white;
}

.updated-box {
  height: 22px;
  width: 80px;
  background: #F84E4E;
  border: 1px solid #CF220A;
  border-radius: 4px;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
.ant-layout-content .clearfix:before,.ant-layout-content  .clearfix:after{
  display: initial
}
.word-break {
  word-wrap: break-word;
}
input.timeInput::-webkit-outer-spin-button,
input.timeInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.timeInput[type=number] {
  -moz-appearance:textfield;
}
.legendPreviousMonth,.legendNextMonth{
  width: 15px;
  height: 15px;
  border-radius: 4px;
  background: rgba(245,166,35,.3);
  border: 1px solid rgba(245,166,35,1);
}
.legendNextMonth{
  background: rgba(74,144,226,.3);
  border: 1px solid rgba(74,144,226,1);
}
.charDesc{
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 22px;
  color: #8C8C8C;
}

.long-description strong {
  font-weight: 900 !important;
}

.ant-table-thead > tr > th {
  border: 1px solid #E8E8E8;
}
.imgPreview {
  width: 450px;
  height: 250px;
}
.ril__image {
  /* width: 50%; */
  /* height: 70%; */
  /* margin-left: 250px!important; */
  object-fit: contain;
  object-position: center;
}
.css-6d2p78 {
  width: 1017px;
  height: 513px;
  object-fit: cover;
}


.signup-card-wrap .ant-card-body{
  width: 90%;
}
