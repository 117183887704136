.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
    font-style: normal;
    text-align: center;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    position: absolute;
    display: inline-block;
    line-height: 46px;
    vertical-align: top;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 16px;
  }
  
  .age {
    display: inline-block;
  }
  
  .age .ant-select {
    width: auto;
    min-width: 100px;
  }
  
  .age .ant-select .ant-select-selection--single {
    border: none;
    box-shadow: none;
    margin-right: 30px;
  }
  
  .save-activity-btn {
    float: right;
    position: relative;
    bottom: 7em;
  }
  
  .btn-wrapper {
    float: right;
    margin-top: 16px;
  }
  
  .btn-wrapper button {
    height: 34px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
  
  .btn-wrapper button:first-child {
    margin-right: 8px;
  }
  
  .create-steps {
    padding: 20px;
    background-color: #fcfeff;
    border: 1px solid rgba(0, 118, 255, 0.2);
    border-radius: 4px;
  }
  
  .featuredImage .featured-img img {
    width: 378px;
    border-radius: 5px;
  }
  
  .featuredImage .featured-img {
    padding: 0;
  }
  
  .create-steps .ant-upload.ant-upload-drag .ant-upload-btn {
    min-height: 170px;
  }
  
  .color, .color .ant-select-arrow {
    color: #1890ff;
  }
  
  .image_details {
    clear: both;
  }
  
  .create-activity .ant-collapse-header {
    background: #e2f4ff;
    border-color: #e2f4ff;
    font-family: "Avenir", sans-serif !important;
    font-weight: 100 !important;
    font-size: 16px;
  }
  
  .addmorebutton {
    padding: 5px;
    border: 1px solid #0076ff;
    color: #0076ff;
  }
  
  .border-color-blue {
    border-color: #bae7ff !important;
  }
  
  .create-activity .ant-collapse {
    border: none;
    background: transparent;
  }
  
  .activityPageScroll {
    /* height: calc( 100vh - 190px); */
    overflow: scroll;
  }
  
  .tabscroll {
    height: calc(100vh - 215px);
    overflow: scroll;
  }
  
  .min-300 {
    max-width: 300px;
  }
  
  .ActivityBorder th {
    height: 3rem;
  }
  
  .ActivityBorder tr {
    margin: 0.5rem;
  }
  
  .btn-wrapper .anticon svg {
    margin: 0;
  }
  
  .step-cancel, .step-cancel:hover {
    color: #9B9B9B;
    border: 1px solid #9B9B9B;
  }
  .text-opt{
    font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 137% */
  
  color: #9B9B9B
  }

  .workshop-approve, .workshop-approve:hover, .workshop-approve:focus {
    color: #ffffff;
    border-radius: 4px;
    background: #68B415;
  }
  
