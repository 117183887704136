
.card-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* margin-bottom: 0; */
    border-radius: 4px;
    margin:0 auto;
}
.t-center{
    text-align: center;
}

.txt-bold{
    font-weight: 500;
}
.m-b{
    margin-bottom: 1em;
}
.mb-0{
    margin-bottom: 0em;
}
.m-rl{
    margin:0em 0.5em;
}
.card-radius{
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
  width:400px;
}
.btn-signin{
    padding-top: 1.2em;
    border-top: 1px solid rgba(0,0,0,0.09);
    margin-bottom: 10px;
}
a:focus{
    text-decoration: none;
}
.mbl-card-radius {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1)!important;
    border-radius: 12px;
}
.text-darkBlack {
    color: #000!important;
}
.signupLogo {
    margin: 0;
}
.max-dev-NotFoundTxt {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #EA1F59;
    background: #FFDCE6;
    border-radius: 5px;
    padding: 12px;
}

@media (max-width: 576px) { 
    .logo, .max-signup-title, .max-alreadymem, .max-dev-forgotPass,
    .max-dev-stickTxt, .max-btn-hide, .signupLogo, .max-dev-NotFoundTxt {
        display: none!important;
    }
    .txt-footr {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #000000;
    }
    .mbl-logo {
        display: block;
        margin: 15px auto 30px auto;
        height: 40px;
    }
    .mbl-signup-logo {
        width: 249px;
        display: block;
        margin: 15px auto 20px auto;
        height: 40px;
    }
    .mbl-cardStart {
        width: 90%;
        background: #FFFFFF;
    }
    .card-wrap {
        border-radius: 6px;
        background: #fafafa;
    }
    .card-w-auto {
        width: auto!important;
        box-shadow: none;
    }
    .btn-social, .ORTXT {
        display: none!important;
    }
    .social-ico {
        cursor: pointer;
    }
    .lap-footer {
        margin-bottom: 3rem;
    }
    .mbl-accountTxt {
        color: #333!important;
        font-size: 15px!important;
        font-weight: normal!important;
    }
    .mbl-signupTxt {
        text-decoration: none!important;
        color: #0076FF;
        font-size: 15px!important;
        font-weight: 800!important;
    }
    .mbl-signup-card {
        width: 90%!important;
        border: 1px solid #fff;
    }
    .mbl-signup-title {
        color: #000!important;
        font-weight: 500!important;
    }
    .mbl-alreadymem {
        color: #828282!important;
        font-weight: 500!important;
    }
    .mbl-alreadymem > a > span {
        font-weight: 800!important;
    }
    .text-secondary {
        color: #000!important;
    }
    .ant-card-body {
        width: 90%;
    }
    .mbl-btn-bottom {
        margin-bottom: 1rem!important;
    }
    .mbl-signup-input::placeholder {
        font-family: Avenir;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
        color: #BDBDBD;
    }
    .mbl-forgot-logo {
        height: 40px;
    }
    .mbl-dev-forgotPass {
        height: 90%;
        width: 90%;
    }
    .mbl-forgotPass-input > div > .FontAvenirMedium {
        text-transform: initial!important;
    }
    .mbl-fontTxt {
        text-transform: uppercase;
        color: #000000!important;
    }
 }
 @media (min-width: 576px) { 
    .mbl-footer, .txt-footr, .mbl-signup-title, .mbl-alreadymem, .mbl-signup-logo, .mbl-forgot-logo,
    .mbl-dev-forgotPass, .lap-footer, .mbl-btn-hide {
        display: none!important;
    }
    .mbl-fontTxt {
        font-size: 30px!important;
        color: #000000!important;
        margin-top: 1.5rem;
    }
    .mbl-logo {
        margin-top: 1.5rem;
        height: 50px;
    }
    .max-dev-stickTxt {
        margin-bottom: 1.5rem;
    }
    .mbl-card-radius > .ant-card-body {
        padding: 0;
        width: 390px;
        margin-bottom: 3rem;
    }
 }
