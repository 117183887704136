.reg_studTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
}
.breadcrumbCursor {
    cursor: pointer!important;
}
.breadcrumbCursor:hover, .breadcrumbCursor:focus {
    cursor: pointer!important;
    color: #40a9ff!important;
}
.registeredBreadcrumb {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
}
.registerTag {
    background: rgb(246, 255, 237) none repeat scroll 0% 0%;
    color: #52C41A;
    border: 1px solid #B7EB8F;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    text-align: center;
}
.pendingTag {
    color: #FA8C16;
    background: #FFF7E6;
    border: 1px solid #FFD591;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    text-align: center;
}
.notAttendedTag {
    color: #EA1F59;
    background: #FFDCE6;
    border: 1px solid #EA1F59;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    text-align: center;
}
.ant-tabs-tab.tab-color {
    color: #333!important;
}
.ant-tabs-tab.tab-color1 {
    color: #828282!important;
}
.meetingText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 2px!important;
    margin-top: 2px!important;
}
.conText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 2px!important;
    margin-top: 2px!important;
    color: #333333;
}